import React from 'react';
import { BiSolidBell } from 'react-icons/bi';
import { useState } from 'react';

export default function ActiveLog() {
  const [steps, setSteps] = useState([
    { id: 1, text: 'Checked Notification', date: '22 NOV 7:20 PM' },
    { id: 2, text: 'Requested Manual Payment #4219423', date: '21 DEC 11:21 PM' },
    { id: 3, text: 'Viewed RIO IV Deal Page', date: '21 DEC 9:28 PM' },
    { id: 4, text: 'Mobile Number Updated', date: '20 DEC 3:52 PM' },
    { id: 5, text: 'Checked Notification', date: '19 DEC 11:35 PM' },
    { id: 6, text: 'KYC Verified', date: '18 DEC 4: 41 PM' },
  ]);


  return (
    <div className=" p-8  bg-gray-100  ">
    <div className="p-4 bg-white rounded-md">
      <h2 className="text-lg font-bold mb-1">Activity Overview</h2>
      <h2 className="text-sm font-bold text-[#48BB78] mb-7">Recent Activities</h2>

      <div className="flex flex-col space-y-4">
        {steps.map((step) => (
          <div key={step.id} className="flex items-center h-10">
            <div className="flex flex-col items-center">
              <BiSolidBell className="text-blue-500 " />
              <div className="w-0.5 h-8 mt-1 bg-gray-300 rounded-md"></div>
            </div>
            <div className='flex flex-col ml-3 mb-3'>
            <p className="text-gray-700 font-medium text-sm whitespace-pre">
  {step.text.split("").map((letter, index) => (
    <React.Fragment key={index}>
      {index > 0 && index % 24 === 0 && <br />}
      <span className="inline-block letter-shadow1">{letter === ' ' ? '\u00A0' : letter}</span>
    </React.Fragment>
  ))}
</p>
              <p className='text-gray-400 text-xs font-semibold '>{step.date}</p>
            </div>
          </div>
        ))}
      </div>
        <button type="submit" className="bg-blue-500  text-white rounded-md drop-shadow-md px-10 py-2 mt-10">
          LOAD MORE
        </button>
      </div>
      </div>
   
  );
}