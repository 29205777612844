import React, { useState, useRef, useEffect } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  FaSignOutAlt,
  FaTimes,
  FaUserCircle,
  FaBell,
  FaUser,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { BsFillImageFill } from "react-icons/bs";
import { AiFillTool } from "react-icons/ai";
import {
  IoDocumentText,
  IoRocket,
  IoSettingsSharp,
  IoStatsChartSharp,
  IoWifiSharp,
} from "react-icons/io5";
import { FaHome } from "react-icons/fa";
import sidelogo from "../../assets/Logo (2).png";
import ternari from "../../assets/Icon.png";
import BreadCrumbs from "./Breadcrumbs";
import { BiSolidCreditCard, BiSupport } from "react-icons/bi";
import { MdVideocam } from "react-icons/md";
import { LuMonitor } from "react-icons/lu";
import axios from "axios";
import { Modal, Button } from "antd";

const rioApiUrl = process.env.REACT_APP_API_URL;

const NavBar = ({ open }) => {
  const [nav, setNav] = useState(false);
  const [showlog, setShowlog] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [istog, settog] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState("/homepage/dashboard");

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        settog(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleSignOut = async () => {
    try {
      const response = await axios.post(`${rioApiUrl}/user/logout`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(response);
      localStorage.removeItem("user_type");
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const handleNotification = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authentication token not found.");
        return;
      }
      const response = await axios.get(`${rioApiUrl}/user/user-notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { notifications } = response.data;
      setNotifications(notifications);
      setNotificationVisible(true);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const clearNotifications = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Authentication token not found.");
        return;
      }
      const response = await axios.get(
        `${rioApiUrl}/user/user/clear-notification`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotifications([]);
    } catch (error) {
      console.error("Error clearing notifications:", error);
    }
  };

  const handleMenuClick = (path) => {
    setActiveMenu(path);
    setNav(false);
    navigate(path);
  };

  const menuItems = [
    { name: "Dashboard", path: "/homepage/dashboard", icon: <FaHome /> },
    {
      name: "My Portfolio",
      path: "/homepage/myportfolio",
      icon: <IoStatsChartSharp />,
    },
    {
      name: "All Transactions",
      path: "/homepage/alltransactions",
      icon: <BiSolidCreditCard />,
    },
    {
      name: "Opportunities",
      path: "/homepage/opportunities",
      icon: <AiFillTool />,
    },
  ];

  const accountPages = [
    { name: "My Profile", path: "/homepage/myprofile", icon: <FaUser /> },
    { name: "KYC", path: "/homepage/kyc", icon: <IoDocumentText /> },
    {
      name: "My Documents",
      path: "/homepage/documents",
      icon: <BsFillImageFill />,
    },
    { name: "Refer & Earn", path: "/homepage/refer&earn", icon: <IoRocket /> },
  ];

  const learnCenter = [
    { name: "Blog", path: "/homepage/blog", icon: <IoWifiSharp /> },
    { name: "Webinar", path: "/homepage/webinar", icon: <MdVideocam /> },
  ];

  const systemPages = [
    {
      name: "Notification Center",
      path: "/homepage/notificationCenter",
      icon: <LuMonitor />,
    },
    { name: "Support", path: "/homepage/support", icon: <BiSupport /> },
  ];

  return (
    <div className="w-full lg:w-4/5 h-16 flex flex-row md:justify-between px-4 py-2 fixed left-1/5 right-0 top-0 bg-gray-100 z-20">
      <div className="flex flex-row justify-center items-center">
        <div className="w-12 h-6">
          <RxHamburgerMenu
            className="w-6 h-6 sm:w-8 sm:h-6 lg:hidden text-nav-ash cursor-pointer"
            onClick={() => setNav(!nav)}
          />
        </div>

        <div className="flex items-center gap-1 flex-row">
          <h2 className="text-2xl font-semibold text-[#A0AEC0]"></h2>
          <BreadCrumbs className="" />
        </div>
      </div>

      <div className="flex flex-col justify-start px-6 gap-2 ml-3 h-full bg-secondary">
        <div
          className={`${
            nav && "fixed inset-0 bg-secondary duration-700 ease-in-out"
          }`}
        >
          <div
            className={`flex flex-col w-[250px] h-full lg:hidden absolute left-0 top-0 bottom-0 bg-white ${
              nav ? "translate-x-0" : "-translate-x-full overflow-hidden"
            } ease-in-out duration-600 overflow-y-scroll`}
          >
            <div className="flex bg-regal-blue mt-5 pt-3 rounded-lg">
              <img
                src={sidelogo}
                alt="Riofin"
                className="cursor-pointer mt-5 ml-3 w-10 h-10"
              />
              <h1 className="text-black text-lg mt-7 font-bold ml-2">
                RIOFIN ASSETS
              </h1>
              <FaTimes
                className="w-6 h-6 ml-3 mt-0 mb-3 text-blue-500 cursor-pointer"
                onClick={() => setNav(!nav)}
              />
            </div>
            <hr className="text-gray-700 mt-2" />
            <div className="h-46 px-6 py-3 flex flex-col bg-secondary gap-6">
              <div className="flex flex-row justify-between gap-0 items-center"></div>
            </div>
            <div className="h-px w-full bg-gray-600"></div>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black ml-3 mr-3 font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] ml-5 text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      } flex items-center justify-center`}
                    >
                      {item.icon}
                    </span>
                    <span className="ml-5 font-bold mt-1 flex">
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

            <h2 className="p-2 px-[24px] py-4 font-bold text-[#2272FF]">
              ACCOUNT PAGES
            </h2>
            <ul>
              {accountPages.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black ml-3 mr-3 font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] ml-5 text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      } flex items-center justify-center`}
                    >
                      {item.icon}
                    </span>
                    <span className="ml-5 font-bold mt-1 flex">
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

            <h2 className="p-2 px-[24px] py-4 font-bold text-[#2272FF]">
              LEARN CENTER
            </h2>
            <ul>
              {learnCenter.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black ml-3 mr-3 font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] ml-5 text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      } flex items-center justify-center`}
                    >
                      {item.icon}
                    </span>
                    <span className="ml-5 font-bold mt-1 flex">
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>

            <h2 className="p-2 px-[24px] py-4 font-bold text-[#2272FF]">
              SYSTEM PAGES
            </h2>
            <ul>
              {systemPages.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black ml-3 mr-3 font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] ml-5 text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      } flex items-center justify-center`}
                    >
                      {item.icon}
                    </span>
                    <span className="ml-5 font-bold mt-1 flex">
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <button
              className="flex items-center px-5 py-3 ml-3 mr-3 mb-3 mt-3 bg-red-600 text-white rounded-lg"
              onClick={handleSignOut}
            >
              <FaSignOutAlt className="mr-3" />
              Logout
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center gap-5">
        <div className="cursor-pointer md:block hidden" onClick={() => handleNotification()}>
          <Link to="/homepage/notificationCenter">
            <FaBell className="text-gray-500 w-6 h-6" />
          </Link>
        </div>
        <span className="lg:flex hidden md:block items-center ml-3">
          <div ref={menuRef}>
            <FaUserCircle
              className="h-10 w-8 cursor-pointer"
              onClick={() => settog(!istog)}
            />
            {istog && (
              <div className="fixed top-16 md:right-2 an sm:right-14 h-fit w-[160px] bg-white border-1 border-gray-200 md:flex hidden flex-col rounded-xl z-20 shadow border gap-1 p-4 ">
                <div className="flex flex-col p-2 gap-2">
                  <span className="font-semibold">Account</span>
                  <span className="text-nav-ash font-md capitalize">
                    {localStorage.getItem("")}
                  </span>
                </div>
                <div className="w-full h-px bg-gray-300 mb-2"></div>
                <Link to="/homepage/myprofile" onClick={() => settog(false)}>
                  <span className="flex flex-row items-center gap-2 cursor-pointer text-gray-500 font-medium px-2 py-1 font-md ">
                    <FaUser className="text-[18px]" />
                    Profile
                  </span>
                </Link>
                <Link to="/">
                  <div className="flex flex-col justify-between">
                    <span
                      className="flex flex-row items-center  text-gray-500  gap-2 cursor-pointer px-2 py-1 font-medium font-md"
                      onClick={handleSignOut}
                    >
                      <FaSignOutAlt />
                      Sign out
                    </span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </span>
        {/* <img src={ternari} alt="ternari" className="w-10 h-10" /> */}
      </div>
      {/* <Modal
        title="Notifications"
        open={notificationVisible}
        onCancel={() => setNotificationVisible(false)}
        footer={[
          <Button key="clear" onClick={clearNotifications}>
            Clear Notifications
          </Button>,
          <Button
            key="close"
            type="primary"
            onClick={() => setNotificationVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <ul>
          {notifications.length === 0 ? (
            <li>No new notifications.</li>
          ) : (
            notifications.map((notification, index) => (
              <li key={index}>
                <div>
                  <strong>Title:</strong> {notification.title || "No Title"}
                </div>
                <div>
                  <strong>Message:</strong>{" "}
                  {notification.message || "No Message"}
                </div>
              </li>
            ))
          )}
        </ul>
      </Modal> */}
    </div>
  );
};

export default NavBar;
