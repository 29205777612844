import Slider from "react-slick";
import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdStarRate } from "react-icons/md";
import DataTable from "react-data-table-component";
import { GiTrophyCup } from "react-icons/gi";
import ProgressBar from "@ramonak/react-progress-bar";

const Active = () => {
  const customStyles = {
    Headers: {
      style: {},
    },
    headRow: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    rows: {
      style: {
        borderRadius: "10px",
        boxShadow: "0 3px #D3D3D3",
        backgroundColor: "#F0FFF0",
        marginBottom: "10px",
      },
    },
    cells: {
      style: {
        // fontWeight: "bold",
        // fontSize: "12px"
      },
    },
  };
  const datas = [
    {
      id: "1",
      project_listed: "Moorthy Infra",
      PA: "24% PA",
      tensure: "48 months",
      fully_funded: "4.5 Cr",
    },
    {
      id: "2",
      project_listed: "Lloyd RE",
      PA: "36% PA",
      tensure: "36 months",
      fully_funded: "2.5 Cr",
    },
    {
      id: "1",
      project_listed: "Tata P1",
      PA: "18% PA",
      tensure: "18 months",
      fully_funded: "9.5 Cr",
    },
    {
      id: "2",
      project_listed: "Jindal Steel",
      PA: "14% PA",
      tensure: "12 months",
      fully_funded: "5.5 Cr",
    },
    {
      id: "1",
      project_listed: "Tata P1",
      PA: "18% PA",
      tensure: "18 months",
      fully_funded: "9.5 Cr",
    },
    {
      id: "2",
      project_listed: "Jindal Steel",
      PA: "14% PA",
      tensure: "12 months",
      fully_funded: "5.5 Cr",
    },
    {
      id: "1",
      project_listed: "Tata P1",
      PA: "18% PA",
      tensure: "18 months",
      fully_funded: "9.5 Cr",
    },
    {
      id: "2",
      project_listed: "Jindal Steel",
      PA: "14% PA",
      tensure: "12 months",
      fully_funded: "5.5 Cr",
    },
  ];
  // Add more data as needed

  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.project_listed,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "PA Returns",
      selector: (row) => row.PA,
    },
    {
      name: "Tenure",
      selector: (row) => row.tensure,
    },
    {
      name: "Fully Funded",
      selector: (row) => row.fully_funded,
      cell: (row) => (
        <>
          <GiTrophyCup className="size-9 text-yellow-400 " />
          <span className="ml-2">{row.fully_funded}</span>
        </>
      ),
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Set slidesToShow to the number of slides you want to show at once
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1164,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("DESCRIPTION");

  const renderContent = () => {
    switch (activeTab) {
      case "DESCRIPTION":
        return (
          <div className="text-black text-sm mb-4 text-justify">
            <p>
              <b>Description</b>
            </p>
            <p>
              Commercial warehouse located in the prime location of
              Sriperumbudur. Waiting to be fully funded.
            </p>
          </div>
        );
      case "NEAR BY":
        return (
          <div>
            <div className="text-black text-sm mb-4  text-justify">
              <p>
                <b>Near by</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      case "MAP":
        return (
          <div>
            <div className="text-black text-sm mb-4  text-justify">
              <p>
                <b>Tenants</b>
              </p>
              <p>
                Commercial warehouse located in the prime location of
                Sriperumbudur. Waiting to be fully funded.
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <div className="flex lg:flex-row flex-col lg:h-[560px] h-full gap-5 p-5">
        <div className="bg-white lg:w-2/5 h-full rounded-xl p-5 lg:-mt-0 -mt-14">
          <div className="" data-aos="fade-up">
            <h1 className="text-black font-bold text-lg pb-2 -mt-2">
              Active Deals
            </h1>
            <Slider {...settings} className="-pt-10">
              {/* Slide 1 */}
              <div className="flex flex-col items-center justify-center">
                <div className="font-inter">
                  <div>
                    <div className="  ">
                      <div className=" bg-[#D6FFDD] rounded-[18px]">
                        <div className="flex gap-5 pb-5 justify-end items-center pr-10">
                          <button class="text-black  bg-[#00FF00] font-bold  p-2 text-xs rounded-lg">
                            Capital Protected
                          </button>
                          <button class="text-black p-2 bg-[#00FF00] font-bold  text-xs rounded-lg">
                            Trending
                          </button>
                        </div>
                        <p className="text-mygreen px-4 text-xs font-bold md:mb-0">
                          Real Estate 1
                        </p>
                        <div className="md:ml-0 px-4">
                          <h1 className="text-black text-md md:text-1xl font-bold py-1">
                            CRE-II
                          </h1>
                          <p className="text-black text-sm mb-4 font-semibold">
                            Mappedu
                          </p>
                          <div className="flex items-center justify-between gap-1">
                            <div className="flex flex-col">
                              <div className="text-green-700 text-sm">67%</div>
                              <div
                                className="mt-2"
                                style={{
                                  width: "400%",
                                  height: "5px",
                                  backgroundColor: "#ddd",
                                  borderRadius: "4px",
                                  overflow: "hidden",
                                  color: "#2272FF",
                                }}
                              >
                                <div
                                  style={{
                                    width: `67%`,
                                    height: "100%",
                                    backgroundColor: "green",
                                    borderRadius: "4px",
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <p className="text-lg font-bold">₹ 1.2 Crore</p>
                            </div>

                            {/* <div className="flex ml-2 mt-1 md:ml-8 px-3">
                              {[1, 2, 3, 4, 5].map((_, index) => (
                                <MdStarRate
                                  key={index}
                                  className="text-black"
                                />
                              ))}
                            </div> */}
                          </div>

                          <br />

                          {/* Tab section 1 */}
                          <div>
                            <div className="flex absolute -m-4 ml-0   flex-row md:mr-3 mb-2 ">
                              <button
                                className={`text-xs font-semibold text-black ${
                                  activeTab === "DESCRIPTION"
                                    ? "border-b-2  border-black"
                                    : ""
                                }`}
                                onClick={() => setActiveTab("DESCRIPTION")}
                              >
                                DESCRIPTION
                              </button>
                              <button
                                className={`px-5 font-semibold text-xs text-black ml-1 ${
                                  activeTab === "NEAR BY"
                                    ? "border-b-2 border-black"
                                    : ""
                                }`}
                                onClick={() => setActiveTab("NEAR BY")}
                              >
                                NEAR BY
                              </button>
                              <button
                                className={`px-2 text-xs font-semibold text-black ml-1 ${
                                  activeTab === "MAP"
                                    ? "border-b-2 border-black"
                                    : ""
                                }`}
                                onClick={() => setActiveTab("MAP")}
                              >
                                TENANTS
                              </button>
                            </div>
                            <hr className="top-1 lg:mb-3 text-gray" />
                            {renderContent()}
                          </div>

                          <div className="flex font-sans flex-row mb-0 text-black gap-2 font-medium">
                            <p className="px-1 text-xs">ROI %</p>
                            <p className="pl-6 text-xs">TENURE</p>
                            <p className="pl-2 text-xs">MIN INVESTMENT</p>
                          </div>
                          <div className="flex gap-4 mt-2 ">
                            <button className="bg-gray rounded-md px-2 py-1 text-xs text-[#00ff00] bg-black">
                              18% PA
                            </button>
                            <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]  bg-black ">
                              4 Yr
                            </button>
                            <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]   bg-black">
                              1 Lakh
                            </button>
                          </div>
                          <br />
                          <div className="pb-3 space-y-3">
                            <button class=" border border-[#00FF00] text-black font-bold text-xs py-1 rounded-lg w-full">
                              INVEST NOW
                            </button>
                            <br />
                            <button class="bg-[#00FF00]  text-black font-bold w-full rounded-lg text-xs py-1">
                              LEARN MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Slide 2 */}
              <div className="flex flex-col items-center justify-center">
                <div className="font-inter">
                  <div>
                    <div className="">
                      <div className=" bg-[#D6FFDD] w-full rounded-[18px] ">
                        <div className="flex gap-5  justify-end items-center pr-10">
                          <button class="text-black p-2 bg-[#00FF00] font-bold text-xs rounded-lg">
                            Capital Protected
                          </button>
                          <button class="text-black bg-[#00FF00] font-bold h-8 w-20 text-xs rounded-lg">
                            Trending
                          </button>
                        </div>
                        <p className="text-mygreen px-4 text-xs font-bold md:mb-0 mt-5">
                          Real Estate 1
                        </p>
                        <div className="md:ml-0 px-4">
                          <h1 className="text-black text-md md:text-1xl font-bold py-1">
                            CRE-II
                          </h1>
                          <p className="text-black text-sm mb-4 font-semibold">
                            Mappedu
                          </p>
                          <div className="flex items-center justify-between gap-1">
                            <div className="flex flex-col">
                              <div className="text-green-700 text-sm">67%</div>
                              <div
                                className="mt-2"
                                style={{
                                  width: "400%",
                                  height: "5px",
                                  backgroundColor: "#ddd",
                                  borderRadius: "4px",
                                  overflow: "hidden",
                                  color: "#2272FF",
                                }}
                              >
                                <div
                                  style={{
                                    width: `67%`,
                                    height: "100%",
                                    backgroundColor: "green",
                                    borderRadius: "4px",
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <p className="text-lg font-bold">₹ 1.2 Crore</p>
                            </div>

                            {/* <div className="flex ml-2 mt-1 md:ml-8 px-3">
                              {[1, 2, 3, 4, 5].map((_, index) => (
                                <MdStarRate
                                  key={index}
                                  className="text-black"
                                />
                              ))}
                            </div> */}
                          </div>

                          <br />

                          {/* Tab section 2*/}
                          <div>
                            <div className="flex absolute -m-4 ml-0  flex-row md:mr-3 mb-2 ">
                              <button
                                className={`text-xs font-semibold text-black ${
                                  activeTab === "DESCRIPTION"
                                    ? "border-b-2  border-black"
                                    : ""
                                }`}
                                onClick={() => setActiveTab("DESCRIPTION")}
                              >
                                DESCRIPTION
                              </button>
                              <button
                                className={`px-5 font-semibold text-xs text-black ml-1 ${
                                  activeTab === "NEAR BY"
                                    ? "border-b-2 border-black"
                                    : ""
                                }`}
                                onClick={() => setActiveTab("NEAR BY")}
                              >
                                NEAR BY
                              </button>
                              <button
                                className={`px-2 text-xs font-semibold text-black ml-1 ${
                                  activeTab === "MAP"
                                    ? "border-b-2 border-black"
                                    : ""
                                }`}
                                onClick={() => setActiveTab("MAP")}
                              >
                                TENANTS
                              </button>
                            </div>
                            <hr className="top-1 lg:mb-3 text-gray" />
                            {renderContent()}
                          </div>

                          <div className="flex font-sans flex-row mb-0 text-black gap-2 font-medium">
                            <p className="px-1 text-xs">ROI %</p>
                            <p className="pl-6 text-xs">TENURE</p>
                            <p className="pl-2 text-xs">MIN INVESTMENT</p>
                          </div>
                          <div className="flex gap-4 mt-2 ">
                            <button className="bg-gray rounded-md px-2 py-1 text-xs text-[#00ff00] bg-black">
                              18% PA
                            </button>
                            <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]  bg-black ">
                              4 Yr
                            </button>
                            <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]   bg-black">
                              1 Lakh
                            </button>
                          </div>
                          <br />
                          <div className="pb-3 space-y-3">
                            <button class=" border border-[#00FF00] text-black font-bold text-xs py-1 rounded-lg w-full">
                              INVEST NOW
                            </button>
                            <br />
                            <button class="bg-[#00FF00]  text-black font-bold w-full rounded-lg text-xs py-1">
                              LEARN MORE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Add more slides with content as needed */}
            </Slider>
          </div>
        </div>
        <div className="content  ml-0 md:ml-auto rounded-xl md:w-full p-4 font-bold bg-white">
          <div className="ml-0 md:ml-auto">
            <p className="font-bold text-xl">Past Deals</p>
            <p className="text-md md:text-lg text-gray-500 font-medium pt-2">
              Fully Funded Projects In The Past
            </p>
            <div className="flex  w-full   flex-col justify-center items-center">
              <DataTable
                fixedHeader
                fixedHeaderScrollHeight="425px"
                className="w-full h-full"
                columns={columns}
                data={datas}
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Active;
