import React, { useState } from "react";
import { Link } from "react-router-dom";
import Img from "../../../assets/Logo (1).png";
import { FaCheckCircle } from "react-icons/fa";

const Bankkyc = () => {
  const [id_number, setAadhaarNumber] = useState("");

  const handleSubmitt = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token is missing from local storage");
      return;
    }

    try {
      const response = await fetch(
        "https://kyc-api.surepass.io/api/v1/aadhaar-v2/generate-otp",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id_number }),
        }
      );

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        return;
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="md:p-9 p-5"></div>
      <div className="bg-[#F6F9FF] ml-3 md:mr-8 mr-3 lg:mr-8 border border-blue-500 shadow-lg rounded-lg lg:flex sm:grid-cols-1">
        {/* Left column - Profile Card */}
        <div className="bg-white lg:w-96 m-4 rounded-lg">
          <img
            src={Img}
            alt="Your Alt Text"
            className="h-10 mt-4 ml-8 rounded-lg"
          />
          <div className="pl-8 pt-4 relative">
            <Link to="/homepage/profile">
              <p className="py-5 flex justify-start pl-3 items-center gap-3 hover:bg-white hover:text-black relative">
                <FaCheckCircle className="bg-white text-[#25C348] rounded-full text-xl" />
                Bank Details
              </p>
            </Link>
          </div>
        </div>

        {/* Right column - Information and Form */}
        <div className="lg:w-2/2 lg:w-full p-1">
          <div>
            <h2 className="font-bold lg:text-lg text-center pb-6 pt-5">
              Account details
            </h2>
            {/* Form */}
            <form className="flex flex-col items-center gap-4" onSubmit={handleSubmitt}>
              <div className="flex flex-col md:flex-row lg:flex-row md:gap-10 lg:gap-24">
                <div className="flex flex-col">
                  <label htmlFor="accountNumber" className="text-[#1d5ca2]">
                    Account Number
                  </label>
                  <input
                    id="accountNumber"
                    type="text"
                    className="border border-gray-300 rounded-md px-3 py-2"
                    
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="ifscCode" className="text-[#1d5ca2]">
                    Bank IFSC Code
                  </label>
                  <input
                    id="ifscCode"
                    type="text"
                    className="border border-gray-300 rounded-md px-3 py-2"
                    
                  />
                </div>
              </div>

              <div className="text-center">
                <button
                  type="button"
                  className="border border-black rounded-md text-white bg-blue-500 px-7 py-2"
                >
                  Verify
                </button>
              </div>

              <div className="flex flex-col md:flex-row lg:flex-row md:gap-10 lg:gap-24">
                <div className="flex flex-col">
                  <label htmlFor="branchName" className="text-[#1d5ca2]">
                    Branch Name
                  </label>
                  <input
                    id="branchName"
                    type="text"
                    className="border border-gray-300 rounded-md px-3 py-2"
                    
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="bankName" className="text-[#1d5ca2]">
                    Bank Name
                  </label>
                  <input
                    id="bankName"
                    type="text"
                    className="border border-gray-300 rounded-md px-3 py-2"
                    
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="border border-black rounded-md text-white bg-blue-500 px-7 mb-5 py-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bankkyc;
