// import Slider from "react-slick";
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdStarRate } from "react-icons/md";
import DataTable from "react-data-table-component";
import { GiTrophyCup } from "react-icons/gi";
import graph1 from "../../../assets/Group 6.png";
import graph2 from "../../../assets/Group 4.png";
import graph3 from "../../../assets/Group 7.png";
import gifts from "../../../assets/3d render blue gift box with gold ribbon package.png";
import { CiCircleChevRight } from "react-icons/ci";
import bg from "../../../assets/opportunitiesImage.png";
import Opportunitie from "../../../assets/Opportunities2.png";
import Airport from "../../../assets/1.png";
import metro from "../../../assets/2.png";
import railway from "../../../assets/3.png";
import bus from "../../../assets/4 (1).png";
import { IoBulbSharp } from "react-icons/io5";
import { SiJirasoftware } from "react-icons/si";
import { FaRupeeSign, FaStar } from "react-icons/fa";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import {
  Col,
  Flex,
  InputNumber,
  Progress,
  Row,
  Slider,
  Typography,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// const Opportunities = () => {
//     const [] = useState({
//         NetWorth: { value: 5300000, percentage: 121 },
//         todaysGain: { value: 2173, percentage: 0.05 },
//         totalProfitsEarned: { value: 954000, percentage: 18 },
//         capitalInvested: { value: 4356000, percentage: 100 },
//       });

//       const months = [
//          'Next Month', 'Next Day', 'Next Year'
//       ];

//       const [selectedMonth, setSelectedMonth] = useState('');

//       const handleMonthChange = (e) => {
//         setSelectedMonth(e.target.value);
//       }

//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         autoplay: true,
//         autoplaySpeed: 2500,
//         arrows: false,
//         responsive: [
//           {
//             breakpoint: 1164,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               infinite: true,
//               dots: true,
//               arrows: false,
//             },
//           },
//           {
//             breakpoint: 820,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               infinite: true,
//               dots: true,
//               arrows: false,
//             },
//           },
//           {
//             breakpoint: 768,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               infinite: true,
//               dots: true,
//               arrows: false,
//             },
//           },
//           {
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               initialSlide: 1,
//               dots: true,
//               arrows: false,
//             },
//           },
//           {
//             breakpoint: 480,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               dots: true,
//               arrows: false,
//             },
//           },
//         ],
//       };

//     const [activeTab, setActiveTab] = useState('DESCRIPTION');

//     const renderContent = () => {
//         switch (activeTab) {
//             case 'DESCRIPTION':
//                 return (
//                     <div className='text-black text-sm mb-4 text-justify'>
//                         <p><b>Description</b></p>
//                         <p>Commercial warehouse located in the prime location of Sriperumbudur. Waiting to be fully funded.</p>
//                     </div>
//                 );
//             case 'NEAR BY':
//                 return (
//                     <div>
//                         <div className='text-black text-sm mb-4  text-justify'>
//                             <p><b>Near by</b></p>
//                             <p>Commercial warehouse located in the prime location of Sriperumbudur. Waiting to be fully funded.</p>
//                         </div>
//                     </div>
//                 );
//             case 'MAP':
//                 return (
//                     <div>
//                         <div className='text-black text-sm mb-4  text-justify'>
//                             <p><b>Map</b></p>
//                             <p>Commercial warehouse located in the prime location of Sriperumbudur. Waiting to be fully funded.</p>
//                         </div>
//                     </div>
//                 );
//             default:
//                 return null;
//         }
//     };

//     const [activTab2, setActivTab2] = useState('DESCRIPTION');

//     const renderContent2 = () => {
//         switch (activTab2) {
//             case 'DESCRIPTION':
//                 return (
//                     <div className='text-black text-sm mb-4 text-justify'>
//                         <p><b>Description</b></p>
//                         <p>Commercial warehouse located in the prime location of Sriperumbudur. Waiting to be fully funded.</p>
//                     </div>
//                 );
//             case 'NEAR BY':
//                 return (
//                     <div>
//                         <div className='text-black text-sm mb-4  text-justify'>
//                             <p><b>Near by</b></p>
//                             <p>Commercial warehouse located in the prime location of Sriperumbudur. Waiting to be fully funded.</p>
//                         </div>
//                     </div>
//                 );
//             case 'MAP':
//                 return (
//                     <div>
//                         <div className='text-black text-sm mb-4  text-justify'>
//                             <p><b>Map</b></p>
//                             <p>Commercial warehouse located in the prime location of Sriperumbudur. Waiting to be fully funded.</p>
//                         </div>
//                     </div>
//                 );
//             default:
//                 return null;
//         }
//     };
// //for Datatable

// const datas = [
//   {
//     ProjectName: "Moorthy Infra",
//     PAReturns: "24% PA",
//     Tenure: "48 months",
//     FullyFunded:" 4.5 Cr",
//   },
//   {
//     ProjectName: "Lloyd RE",
//     PAReturns: "36% PA",
//     Tenure: "36 months",
//     FullyFunded:"2.5 Cr",
//   },
//   {
//     ProjectName: "Tata P1",
//     PAReturns: "18% PA",
//     Tenure: "18 months",
//     FullyFunded: "9.5 Cr",
//   },
//   {
//     ProjectName: "Jindal Steel",
//     PAReturns: "14% PA",
//     Tenure: "12 months",
//     FullyFunded: "5.5 Cr",

//   },
// ];

// // Add more data as needed

// const columns = [
//   {
//     name: 'Project Name',
//     selector: row => row.ProjectName,
//     style: {
//       fontWeight: "bold",
//     },

//   },
//   {
//     name: 'PA Returns',
//     selector: row => row.PAReturns,

//   },
//   {
//     name: 'Tenure',
//     selector: row => row.Tenure,

//   },
//   {
//     name: 'Fully Funded',
//     selector: row => row.FullyFunded,

//     cell: (row) => (
//       <>
//           <GiTrophyCup className='size-8 text-yellow-400 ' /><span className='ml-2'>{row.FullyFunded}</span>
//       </>
//   ),
//   }
// ];

// const customStyles = {
//   Headers: {
//     style: {

//     },
//   },
//   headRow: {
//     style: {
//       fontWeight: "bold",
//       fontSize:"14px"
//     },
//   },
//   rows:{
//     style:{
//       borderRadius:'10px',
//       boxShadow:"0 3px #D3D3D3",
//       backgroundColor:"#F0FFF0",
//       marginBottom:"10px",
//      }

//   },
//   cells:{
//     style:{

//     }
//   }
// };

//     const [showActiveContent, setShowActiveContent] = useState(true);

//     const toggleContent = (tab) => {
//       setShowActiveContent(tab === 'Active Projects');
//     };

//     return (
//       <div className='flex flex-col bg-[#F8F9FA]'>
//         <div className='lg:pl-8 mt-10  sm:space-x-10 space-x-5 text-xl lg:text-start text-center'>
//             <button onClick={() => toggleContent('Active Projects')}
//                     className={`focus:outline-none font-bold ${showActiveContent ? 'text-[#2272FF]  border-b-2 border-[#2272FF]' : ''}`}>
//                       Active Projects
//             </button>
//             <button onClick={() => toggleContent('Completed Projects')}
//                     className={`focus:outline-none font-bold  ${ !showActiveContent ? 'text-[#2272FF] border-b-2 border-[#2272FF]' : '' }`}>
//                     Completed Projects
//             </button>
//         </div>
//         <div className='grid lg:grid-cols-2 grid-cols-1'>
//           <div>
//             {showActiveContent ? (
//               <div className='content'>
//                  <section className='lg:w-[600px] md:w-[85%] w-full drop-shadow-xl'>
//                     <div className="flex items-center justify-center mt-6 ">
//                         <div className="w-full xl:w-[790px] lg:w-[480px] lg:ml-[0%] lg1:w-[580px] md:ml-[8%] lg1:ml-[5%] px-5 xl:ml-[45%]" data-aos="fade-up">
//                             <Slider {...settings} className="">
//                                 {/* Slide 1 */}
//                                 <div className="flex flex-col items-center justify-center">
//       <div className="pb-5 lg:flex md:mr-56 md:mb-[-35px] mb-[-30px] ml-24 md:block  justify-end items-end space-x-5 flex flex-row">
//         <button className="text-black bg-[#00FF00] font-bold text-xs rounded-full md:px-5 px-2 py-2">Capital Protected</button>
//         <button className="text-black bg-[#00FF00] font-bold text-xs rounded-full px-5  py-2">Trending</button>
//       </div>
//       <div className="font-inter">
//         <div className="bg-[#D6FFDD] lg:w-[550px] w-full rounded-[18px]">
//           <p className="text-green-600 px-4 text-xs font-bold pt-8 md:mb-0">Real Estate</p>
//           <div className="md:ml-0 px-4">
//             <h1 className="text-black text-md md:text-1xl font-bold py-1">CRE-II</h1>
//             <p className="text-black text-sm mb-4 font-semibold">Mappedu</p>
//             <div className="flex items-center">
//               <h1 className="text-lg md:text-1xl text-black font-semibold">1.2 Crore</h1>
//               <div className="flex ml-2 mt-1 md:ml-8 px-3">
//                 {[1, 2, 3, 4, 5].map((_, index) => (
//                   <MdStarRate key={index} className="text-black" />
//                 ))}
//               </div>
//             </div>
//             <br />
//             <div>
//               <div className="flex absolute -m-4 ml-0 flex-row md:mr-3 mb-2">
//                 <button
//                   className={`text-xs font-semibold text-black ${activeTab === 'DESCRIPTION' ? 'border-b-2  border-black' : ''}`}
//                   onClick={() => setActiveTab('DESCRIPTION')}
//                 >
//                   DESCRIPTION
//                 </button>
//                 <button
//                   className={`px-5 font-semibold text-xs text-black ml-1 ${activeTab === 'NEAR BY' ? 'border-b-2 border-black' : ''}`}
//                   onClick={() => setActiveTab('NEAR BY')}
//                 >
//                   NEAR BY
//                 </button>
//                 <button
//                   className={`px-2 text-xs font-semibold text-black ml-1 ${activeTab === 'MAP' ? 'border-b-2 border-black' : ''}`}
//                   onClick={() => setActiveTab('MAP')}
//                 >
//                   MAP
//                 </button>
//               </div>
//               <hr className="top-1 lg:mb-3 text-gray" />
//               {renderContent()}
//             </div>
//             <div className="flex font-sans flex-row mb-0 text-black gap-2 font-medium">
//               <p className="px-1 text-xs">ROI %</p>
//               <p className="pl-6 text-xs">TENURE</p>
//               <p className="pl-2 text-xs">MIN INVESTING</p>
//             </div>
//             <div className="flex gap-4 mt-2">
//               <button className="bg-gray rounded-md px-2 py-1 text-xs text-[#00ff00] bg-black">18% PA</button>
//               <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]  bg-black ">4 Yr</button>
//               <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00]   bg-black">1 Lakh</button>
//             </div>
//             <br />
//             <div className="py-20 space-y-3">
//               <button className="border border-[#00FF00] text-black font-bold text-xs py-1 rounded-lg w-full">INVEST NOW</button><br />
//               <button className="bg-[#00FF00]  text-black font-bold w-full rounded-lg text-xs py-1">LEARN MORE</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//                                 {/* Slide 2 */}

//                                 <div className="flex flex-col items-center justify-center">
//                                 <div className="pb-5 lg:flex md:mr-56 md:mb-[-35px] mb-[-30px] ml-24 md:block justify-end items-end space-x-5 flex flex-row">
//         <button className="text-black bg-[#00FF00] font-bold text-xs rounded-full  md:px-5 px-2  py-2">Capital Protected</button>
//         <button className="text-black bg-[#00FF00] font-bold text-xs rounded-full px-5 py-2">Trending</button>
//       </div>
//       <div className="bg-[#D6FFDD] justify-center lg:w-[550px] w-full rounded-[18px]">
//         <div className="font-inter">
//           <p className="text-green-600 px-4 text-xs font-bold pt-8 md:mb-0">Real Estate</p>
//           <div className="md:ml-0 px-4">
//             <h1 className="text-black text-md md:text-1xl font-bold py-1">CRE-II</h1>
//             <p className="text-black text-sm mb-4 font-semibold">Mappedu</p>
//             <div className="flex items-center">
//               <h1 className="text-lg md:text-1xl text-black font-semibold">1.2 Crore</h1>
//               <div className="flex ml-2 mt-1 md:ml-8 px-3">
//                 {[1, 2, 3, 4, 5].map((_, index) => (
//                   <MdStarRate key={index} className="text-black" />
//                 ))}
//               </div>
//             </div>
//             <br />
//             <div>
//               <div className="flex absolute -m-4 ml-0 flex-row md:mr-3 mb-2">
//                 <button
//                   className={`text-xs font-semibold text-black ${activeTab === 'DESCRIPTION' ? 'border-b-2 border-black' : ''}`}
//                   onClick={() => setActiveTab('DESCRIPTION')}
//                 >
//                   DESCRIPTION
//                 </button>
//                 <button
//                   className={`px-5 font-semibold text-xs text-black ml-1 ${activeTab === 'NEAR BY' ? 'border-b-2 border-black' : ''}`}
//                   onClick={() => setActiveTab('NEAR BY')}
//                 >
//                   NEAR BY
//                 </button>
//                 <button
//                   className={`px-2 text-xs font-semibold text-black ml-1 ${activeTab === 'MAP' ? 'border-b-2 border-black' : ''}`}
//                   onClick={() => setActiveTab('MAP')}
//                 >
//                   MAP
//                 </button>
//               </div>
//               <hr className="top-1 lg:mb-3 text-gray" />
//               {renderContent()}
//             </div>
//             <div className="flex font-sans flex-row mb-0 text-black gap-2 font-medium">
//               <p className="px-1 text-xs">ROI %</p>
//               <p className="pl-6 text-xs">TENURE</p>
//               <p className="pl-2 text-xs">MIN INVESTING</p>
//             </div>
//             <div className="flex gap-4 mt-2">
//               <button className="bg-gray rounded-md px-2 py-1 text-xs text-[#00ff00] bg-black">18% PA</button>
//               <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00] bg-black">4 Yr</button>
//               <button className="bg-gray rounded-md px-3 py-1 text-xs text-[#00ff00] bg-black">1 Lakh</button>
//             </div>
//             <br />
//             <div className="py-20 space-y-3">
//               <button className="border border-[#00FF00] text-black font-bold text-xs py-1 rounded-lg w-full">INVEST NOW</button><br />
//               <button className="bg-[#00FF00] text-black font-bold w-full rounded-lg text-xs py-1">LEARN MORE</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//                                 {/* Add more slides with content as needed */}
//                             </Slider>
//                         </div>
//                     </div>
//                  </section>
//               </div>
//             ) : (
//               <div className='content ml-5 mr-5 md:ml-10 '>
//                       <div className='mt-6 lg:ml-4 w-full mr-7 md:mr-0 lg1:[200px] drop-shadow-xl rounded-xl bg-white flex flex-col justify-center items-center px-5 py-3'>
//                        <DataTable
//                         columns={columns}
//                         data={datas}
//                         customStyles={customStyles}
//                        />
//                    </div>
//               </div>
//             )}
//           </div>

//       <div className="sticky top-auto md:mx-auto mx-auto sm:mr-5">
//         <div className="bg-[#303030] mt-6 rounded-3xl mb-5  w-[350px] lg:h-[574px] md:text-center p-4 border border-[#E5E5E5]">
//         <div className='flex flex-row gap-16 lg:flex-row lg:items-center lg:justify-between  lg:gap-20'>
//   <p className='font-bold text-xs text-white'>New Project Coming Soon</p>

//   <select
//   id="monthDropdown"
//   onChange={handleMonthChange}
//   value={selectedMonth}
//   className="text-xxs sm:text-xs bg-[#303030] rounded-md py-0.5 sm:py-1 border text-gray-300 border-white"
// >
//   <option value="" disabled>Next Week</option>
//   {months.map((month, index) => (
//     <option key={index} value={month}>{month}</option>
//   ))}
// </select>
// </div>
//          <div className='flex flex-col lg:flex-row lg:gap-3 lg:items-center items-center lg:justify-center'>
//   <div className='mt-5 lg:mt-0'>
//     <img src={graph1} className='mb-3 mt-5'></img>
//     <img src={graph2}></img>
//   </div>
//   <img src={graph3} className='mt-3 w-[200px] lg:ml-3'></img>
// </div>

//           <hr className='text-gray-400 mt-10' />

//           <div className='ml-3 text-left'>
//             <p className='font-semibold mt-6   text-white'>*Warehouse VII Expected</p>
//             <p className='text-xs text-gray-400 mt-2 font-semibold'>Last updated 1 Week Ago</p>
//             <button className='text-white py-2 mt-3 text-xs bg-gray-500 rounded-md'>Stay Tuned For Updates</button>
//           </div>
//           <img src={gifts} alt="" className='h-[140px] mx-auto my-auto'/>
//           <p className='text-xs text-gray-400 mt-2 font-semibold text-center'>*Pre-Investing Window To Open Soon!</p>
//         </div>
//       </div>
//     </div>
//   </div>

//     );
// };

const Opportunities = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress >= 100 ? 0 : prevProgress + 10;
        return newProgress;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const nav = useNavigate();
  const [stepsCount, setStepsCount] = React.useState(5);
  const [stepsGap, setStepsGap] = React.useState(7);
  const data = [
    {
      name: "Real Estate",
      title: "CRE - II",
      subtitle: "Mappedu",
      raised: "1.2 Crore",
      roi: "18% PA",
      tensure: "4 Yr",
      investing: "1 Lakh",
      taxReturn: "18",
      tensuremonth: "24 months",
      peoples: "₹ 1,29,10,000/₹ 4,50,00,000",
      completed: "29% completed",
    },
  ];
  const [totInvest, settotInvest] = useState(100000);
  const [rate, setrate] = useState(0);
  const [period, setperiod] = useState(0);

  function handleinvestmetchange(e) {
    settotInvest(e);
  }
  function handlereturn(e) {
    setrate(e);
  }
  function handleperiod(e) {
    setperiod(e);
  }

  const pricing = [
    {
      name: "Gross Purchase Price",
      price: " ₹ 7,40,000,000",
    },
    {
      name: "Stamp Duty and Registration",
      price: "  ₹ 0",
    },
    {
      name: "Brokerage",
      price: " ₹ 3,350,000",
    },
    {
      name: "Acquisition Fee",
      price: "₹ 27,750,000",
    },
    {
      name: "Legal Fees",
      price: "₹ 3,200,000",
    },
    {
      name: "Reserves",
      price: "₹ 3,700,000",
    },
    {
      name: "Net Purchase Price",
      price: "₹ 77,80,00,000",
    },
  ];
  function handlenav() {
    nav("/homepage/opportunities/Learnmore/investnow");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const datas = [
    {
      question: "How does Riofin assist in the buying process?",
      answer:
        "Riofin facilitates the buying process by providing comprehensive market insights, property evaluations, and assistance in negotiations to ensure a seamless transaction.",
    },
    {
      question: "How does Riofin assist in the buying process?",
      answer:
        "Riofin facilitates the buying process by providing comprehensive market insights, property evaluations, and assistance in negotiations to ensure a seamless transaction.",
    },
    {
      question: "How does Riofin assist in the buying process?",
      answer:
        "Riofin facilitates the buying process by providing comprehensive market insights, property evaluations, and assistance in negotiations to ensure a seamless transaction.",
    },
    {
      question: "How does Riofin assist in the buying process?",
      answer:
        "Riofin facilitates the buying process by providing comprehensive market insights, property evaluations, and assistance in negotiations to ensure a seamless transaction.",
    },
    {
      question: "How does Riofin assist in the buying process?",
      answer:
        "Riofin facilitates the buying process by providing comprehensive market insights, property evaluations, and assistance in negotiations to ensure a seamless transaction.",
    },
    {
      question: "How does Riofin assist in the buying process?",
      answer:
        "Riofin facilitates the buying process by providing comprehensive market insights, property evaluations, and assistance in negotiations to ensure a seamless transaction.",
    },
  ];
  return (
    <>
      {/* first section */}
      <div className="xs:p-10 p-5 ">
        <div
          className="h-96 rounded-lg bg-cover bg-center flex items-center justify-center"
          style={{
            backgroundImage: `url(${bg})`,
          }}
        >
          {data.map((item, index) => (
            <div className="flex xs:justify-end justify-center item-center xs:pr-5 w-full">
              <div className="bg-[#D6FFDD] p-3 px-5 w-72 text-xs space-y-2  rounded-xl">
                <p className="text-mygreen text-xs">{item.name}</p>
                <h2 className="font-bold text-2xl">{item.title}</h2>
                <p className="text-xs">{item.subtitle}</p>
                {/* <div className="flex flex-row justify-between items-center">
                  <p className="flex flex-col text-base font-medium">
                    {item.raised}
                    <span className="text-xs text-mygreen">Raised</span>
                  </p>
                  <div className="flex flex-row justify-between gap-1">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div> */}
                <div className="flex flex-row justify-between gap-5">
                  <h1 className="font-bold">ROI %</h1>
                  <p className="text-mygreen bg-black w-20 text-center rounded-full">
                    {item.roi}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <h1 className="font-bold">TENURE</h1>
                  <p className="text-mygreen bg-black w-20 text-center rounded-full">
                    {item.tensure}
                  </p>
                </div>
                <div className="flex flex-row justify-between gap-8">
                  <h1 className="font-bold">MIN INVESTING</h1>
                  <p className="text-mygreen bg-black w-20 text-center rounded-full">
                    {item.investing}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="text-center">
                    <h1>Pre Tax Return</h1>
                    <p className="font-medium">{item.taxReturn}%</p>
                  </div>
                  <div className="text-center ">
                    <h1>Tenure</h1>
                    <p className="font-medium">{item.tensuremonth}</p>
                  </div>
                </div>
                <Progress
                  percent={item.taxReturn}
                  strokeColor={"#00ff00"}
                  showInfo={false}
                />
                <p className="text-center">{item.peoples}</p>
                <p className="text-center">{item.completed}</p>
                <div>
                  <button
                    onClick={handlenav}
                    className="text-black active:translate-y-1 active:shadow-lg drop-shadow-lg bg-mygreen w-full py-2 rounded-full"
                  >
                    Invest Now
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap xs:px-10 py-3 px-5 gap-3 items-center bg-gray-100">
        <a href="#whyinvest">
          <p className="cursor-pointer">Why Invest?</p>
        </a>
        <p>/</p>
        <a href="#overview">
          <p className="cursor-pointer">Overview</p>
        </a>
        <p>/</p>
        <a href="#location">
          <p className="cursor-pointer">Location</p>
        </a>
        <p>/</p>
        <a href="#tenancy">
          <p className="cursor-pointer">Tenancy</p>
        </a>
        <p>/</p>
        <a href="#price">
          <p className="cursor-pointer">Project Financials</p>
        </a>
        <p>/</p>
        <a href="#calculator">
          <p className="cursor-pointer">Returns Calculator</p>
        </a>
      </div>

      {/* second section */}
      <div className="xs:p-10 p-5" id="whyinvest">
        <div className="bg-white rounded-xl drop-shadow-2xl p-5 space-y-3">
          <h1 className="text-center font-bold text-2xl">Why Invest?</h1>
          <hr className="bg-mygreen w-1/2 h-0.5 -ml-5" />
          <h3 className="text-lg font-semibold px-3">Perfect Location</h3>
          <p className="px-3">
            The asset is located in Otteri, a major IT/ITeS hub for campuses in
            Chennai. Frequently rated as India&apos;s &apos;Most livable
            city&apos;, Chennai is a major automobile hub and has one of the
            largest IT sectors in the country. The fast growing city is favoured
            by companies due its proximity to Tamilnadu, low cost of living, and
            well educated workforce.
          </p>
        </div>
      </div>

      {/* third section */}
      <div className="xs:p-10 p-5" id="overview">
        <div className="flex md:flex-row flex-col rounded-xl bg-white drop-shadow-2xl">
          <div className="md:w-1/2 w-full h-full ">
            <img
              src={Opportunitie}
              alt="overview Image"
              className="w-full h-full md:rounded-l-xl rounded-t-xl"
            />
          </div>
          <div className="p-5 md:w-1/2 w-full">
            <h1 className="font-bold text-2xl">Overview</h1>
            <hr className="w-full h-0.5 bg-mygreen" />
            <p className="text-base md:text-start text-justify">
              Amar Tech Center is strategically located in the bustling IT hub
              of Pune, this state-of-the-art furnished office space offers a
              range of world-class amenities designed to cater to the diverse
              needs of IT/ITeS businesses. The building is built to global
              standards. The opportunity is to acquire the 3rd and 4th floor of
              the building, which spans 53,210 sqft.
            </p>
          </div>
        </div>
      </div>

      {/* Fourth section */}
      <div className="xs:p-10 p-5" id="location">
        <div className="flex lg:flex-row flex-col rounded-xl bg-white drop-shadow-2xl">
          <div className="lg:w-1/2 w-full h-80 ">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.3929931526!2d-74.1197636548266!3d40.69766374865528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a55b7bb1555%3A0xb5ea9d17bf2ef9c1!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2suk!4v1633841774460!5m2!1sen!2suk"
              allowFullScreen=""
              loading="lazy"
              className="w-full h-full"
            ></iframe>
          </div>
          <div className="p-5 lg:w-1/2 w-full">
            <h1 className="font-bold text-2xl">Location</h1>
            <hr className="w-full h-0.5 bg-mygreen" />
            <p className="text-base md:text-start text-justify">
              The asset is situated in Otteri, a prime segment of SBD East. Its
              proximity to the Chennai International Airport and the forthcoming
              Metro line has significantly amplified commercial interest in the
              area.
            </p>
            <div className="py-3 space-y-3">
              <div className="flex md:flex-row md:gap-8 gap-3 flex-col">
                <div className="flex flex-row gap-3 bg-gradient-to-r from-gray-200 to-transparent rounded-l-full">
                  <img
                    src={Airport}
                    alt="airport"
                    className="bg-white drop-shadow-2xl p-2 rounded-full"
                  />
                  <div className="text-xs flex flex-col justify-center">
                    <h1 className="text-blue-600 text-lg font-semibold">
                      Airport
                    </h1>
                    <p>Chennai International Airport</p>
                  </div>
                </div>
                <div className="flex flex-row gap-3 bg-gradient-to-r from-gray-200 to-transparent rounded-l-full">
                  <img
                    src={metro}
                    alt="airport"
                    className="bg-white drop-shadow-2xl p-2 rounded-full"
                  />
                  <div className="text-xs flex flex-col justify-center">
                    <h1 className="text-blue-600 text-lg font-semibold">
                      Metro
                    </h1>
                    <p>Airport Metro Station</p>
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row md:gap-5 gap-3 flex-col">
                <div className="flex flex-row gap-3 bg-gradient-to-r from-gray-200 to-transparent rounded-l-full">
                  <img
                    src={railway}
                    alt="airport"
                    className="bg-white drop-shadow-2xl p-2 rounded-full"
                  />
                  <div className="text-xs flex flex-col justify-center">
                    <h1 className="text-blue-600 text-lg font-semibold">
                      Railway Station
                    </h1>
                    <p>Meenambakkam Railway Station</p>
                  </div>
                </div>
                <div className="flex flex-row gap-3 bg-gradient-to-r from-gray-200 to-transparent rounded-l-full">
                  <img
                    src={metro}
                    alt="airport"
                    className="bg-white drop-shadow-2xl p-2 rounded-full"
                  />
                  <div className="text-xs flex flex-col justify-center">
                    <h1 className="text-blue-600 text-lg font-semibold">
                      Bus Terminal
                    </h1>
                    <p>Meenambakkam Bus Stop</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fifth section */}
      <div className="xs:p-10 p-5" id="tenancy">
        <div className="bg-white drop-shadow-2xl pt-5">
          <h1 className="text-center font-bold text-2xl">Tenancy</h1>
          <hr className="w-1/2 h-0.5 bg-mygreen" />
          <div className="flex md:flex-row flex-col justify-between">
            <div className="space-y-2 p-10 md:w-[65%]">
              <p className="text-gray-400">Tenants Listed</p>
              <hr className="w-full h-0.5 bg-gray-200" />
              <p className="flex gap-3 items-center">
                <IoBulbSharp className="size-5 text-yellow-300" />
                Warehouse IV LLP
              </p>
              <hr className="w-full h-0.5 bg-gray-200" />
              <p className="flex gap-3 items-center">
                <SiJirasoftware className="size-5 text-blue-600" />
                Shanas Infra
              </p>
              <hr className="w-full h-0.5 bg-gray-200" />
              <p className="flex gap-3 items-center">
                <IoBulbSharp className="size-5 text-yellow-300" />
                Warehouse VI LLP
              </p>
              <hr className="w-full h-0.5 bg-gray-200" />
              <p className="flex gap-3 items-center">
                <SiJirasoftware className="size-5 text-blue-600" />
                Warehouse VI LLP
              </p>
              <hr className="w-full h-0.5 bg-gray-200" />
              <p className="flex gap-3 items-center">
                <IoBulbSharp className="size-5 text-yellow-300" />
                Roadways Infra
              </p>
              <hr className="w-full h-0.5 bg-gray-200" />
              <p className="flex gap-3 items-center">
                <SiJirasoftware className="size-5 text-blue-600" />
                Warehouse VII LLP
              </p>
            </div>
            <div className="md:w-[45%] bg-[#D6FFDD] drop-shadow-2xl flex flex-col items-start justify-center p-5 sm:m-10 m-5 space-y-3 rounded-xl">
              <h1 className="font-bold">Tenant Overview</h1>
              <p className="text-justify">
                Established in 2016, the tenant is India&apos;s largest managed
                workspace platform for enterprises, with a footprint of over 8
                million sq. ft. across 40+ locations in 13 cities and caters to
                500+ organizations across Forbes 2000 companies, global MNCs and
                established unicorns. Established in 2016, the tenant is
                India&apos;s largest managed workspace platform for enterprises.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* sixth section */}
      <div className="xs:p-10 p-5" id="price">
        <div className="flex  flex-col rounded-xl space-y-3 bg-white drop-shadow-2xl sm:p-10 p-5 justify-center">
          <h1 className=" font-bold text-2xl">Project Financials</h1>
          <hr className="w-1/2 h-0.5 bg-mygreen" />
          <div className="flex flex-col gap-3">
            {pricing.map((item, i) => (
              <div className="flex sm:flex-row flex-col justify-between rounded-xl bg-[#D6FFDD] drop-shadow-lg p-3 border border-mygreen">
                <p>{item.name}</p>
                <p>{item.price}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* seventh section */}
      <div className="xs:p-10 p-5" id="calculator">
        <div className="bg-white drop-shadow-2xl md:p-10 p-5 rounded-xl">
          <h1 className="font-bold text-2xl">Returns Calculator</h1>
          <hr className="w-1/2 h-0.5 bg-mygreen mb-3" />
          <div className="flex md:flex-row flex-col gap-5 justify-between items-center w-full">
            <div className="flex flex-col w-full md:w-1/2 gap-3">
              <div className="w-full flex flex-col gap-3">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-semibold">Total Investment</p>
                  <div className=" flex items-center rounded-md bg-mygreen">
                    <FaRupeeSign className="text-xs ml-2" />
                    <InputNumber
                      min={100000}
                      max={1000000}
                      value={totInvest}
                      onChange={handleinvestmetchange}
                      className="bg-mygreen hover:bg-mygreen border-none w-24"
                    />
                  </div>
                </div>
                <div className="bg-gray-50 shadow-lg px-2 rounded-2xl">
                  <Row>
                    <Col span={24}>
                      <Slider
                        min={100000}
                        onChange={handleinvestmetchange}
                        // value={totInvest}
                        value={
                          typeof totInvest === "number" ? totInvest : 100000
                        }
                        max={1000000}
                        step={0.01}
                      />
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="w-full flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-semibold">Return rate (p.a)</p>
                  <div className="px-6 py-[3px] flex items-center rounded-md w-28 h-7 bg-mygreen">
                    <p className="text-xs font-semibold">{rate}%</p>
                  </div>
                </div>
                <div className="bg-gray-50 shadow-lg px-2 rounded-2xl">
                  <Slider
                    min={0}
                    onChange={handlereturn}
                    value={rate}
                    max={100}
                    style={{ color: "green" }}
                  ></Slider>
                </div>
              </div>
              <div className="w-full flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-semibold">Time Period</p>
                  <div className="px-6 py-[3px] flex items-center rounded-md bg-mygreen w-28 h-7">
                    <p className="text-xs font-semibold">{period}Yr</p>
                  </div>
                </div>
                <div className="bg-gray-50 shadow-lg px-2 rounded-2xl">
                  <Slider
                    min={0}
                    onChange={handleperiod}
                    value={period}
                    max={100}
                    style={{ color: "green" }}
                  ></Slider>
                </div>
              </div>
            </div>
            <div className=" flex md:flex-col flex-col-reverse md:w-1/2 w-full justify-center items-center gap-3 ">
              <div className="flex w-full justify-center items-center flex-row gap-5">
                <div className="flex w-full  flex-row gap-2 justify-center items-center">
                  <hr className="md:w-12 w-6 md:h-[5px] h-[2px] bg-black" />
                  <p className="text-xs">Invested Amount</p>
                </div>
                <div className="flex w-full flex-row gap-2 justify-center items-center">
                  <hr className="md:w-12 w-6 md:h-[5px] h-[2px] bg-blue-600 " />
                  <p className="text-xs">Est. Returns</p>
                </div>
              </div>
              <div className="w-[50%]">
                <CircularProgressbar
                  className="w-20"
                  value={80}
                  styles={buildStyles({
                    rotation: 0.25,
                    width: 20,
                    stroke: "#00ff00",
                    strokeWidth: 20,
                    strokeLinecap: "butt",
                    pathColor: `#2272FF`,
                    trailColor: "#000000",
                    backgroundColor: "yellow",
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* faq section */}
      <div className=" w-full">
        <div className="lg:px-10 py-5">
          <div className="flex flex-col gap-1">
            <div className="py-2">
              <h2 className="text-xl md:text-2xl text-primary font-bold text-center pb-2 pt-8">
                FAQ
              </h2>
              <hr className="w-1/2 h-0.5 bg-mygreen mb-5" />
            </div>
            <div className="space-y-5">
              {datas.map((item, index) => (
                <div
                  key={index}
                  className="bg-gray-100 border-b-8 border-r-8 border-blue-500 px-5 py-2 border-t border-l border-t-gray-100 border-l-gray-100 flex flex-col "
                >
                  <button
                    onClick={() => toggleAccordion(index)}
                    className="flex justify-between items-center w-full duration-300"
                  >
                    <span className="text-black text-start pt-2 font-bold sm:text-base text-sm  ">
                      {item.question}
                    </span>

                    {openAccordionIndex === index ? (
                      <TiArrowSortedUp className=" size-5 w-10" />
                    ) : (
                      <TiArrowSortedDown className="size-5 w-10" />
                    )}
                  </button>
                  <div
                    className={`grid overflow-hidden transition-all duration-300 ease-in-out text-black text-sm ${
                      openAccordionIndex === index
                        ? "grid-rows-[1fr] opacity-100"
                        : "grid-rows-[0fr] opacity-0"
                    }`}
                  >
                    <div className="overflow-hidden flex flex-col gap-3 py-1 ">
                      <p className="overflow-hidden text-black text-justify pr-10 ">
                        {item.answer}
                      </p>

                      {/* <p className="overflow-hidden text-black text-justify pr-10 ">{item.para2}</p>
                      <p className="overflow-hidden text-black text-justify pr-10 ">{item.para3}</p> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center bg-blue-500 rounded-md m-10 p-5  gap-5">
        <Link to="/homepage/opportunities/Learnmore/investnow">
          <button className="py-1 active:shadow-2xl active:translate-y-1 bg-mygreen shadow-xl text-black font-semibold text-center sm:text-sm text-xs w-48 h-10 cursor-pointer rounded-2xl mx-auto">
            Invest Now
          </button>
        </Link>
        <Link to="/homepage/support">
          <button className="py-1 active:shadow-2xl active:translate-y-1 bg-mygreen shadow-xl text-black font-semibold text-center sm:text-sm text-xs w-48 h-10 cursor-pointer rounded-2xl mx-auto">
            Talk To Our Specialist
          </button>
        </Link>
      </div>
    </>
  );
};
export default Opportunities;
