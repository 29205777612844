import React, { useState, useEffect, useRef } from "react";
import { IoFolderSharp } from "react-icons/io5";
import { VscKebabVertical } from "react-icons/vsc";
import { Link, Outlet } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { DatePicker } from "antd";
import { BsFiletypePdf } from "react-icons/bs";
import { FaFileVideo } from "react-icons/fa";
import { FaFileAudio } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
// import 'antd/dist/antd.css';

export default function DocumentUpdated() {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [documents, setDocuments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(new Set());

  const containerRef = useRef(null);

  useEffect(() => {
    const storedDocuments = JSON.parse(localStorage.getItem("documents")) || [];
    setDocuments(storedDocuments);
  }, []);

  useEffect(() => {
    const filterDocs = () => {
      let filtered = documents;
      if (selectedDate) {
        filtered = filtered.filter((doc) => doc.date === selectedDate);
      }
      if (searchQuery) {
        filtered = filtered.filter((doc) =>
          doc.text.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      setFilteredDocuments(filtered);
    };

    filterDocs();
  }, [selectedDate, searchQuery, documents]);

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString);
    setShowCalendar(false); // Close calendar when date is selected
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const date = new Date();
    const formattedDate = date.toISOString().split("T")[0];

    const newDocuments = Array.from(files).map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const content = e.target.result;
        const updatedDoc = {
          id: documents.length + index + 1,
          text: file.name,
          date: selectedDate || formattedDate,
          type: file.type,
          content: content, // Store content as base64 string
        };
        setDocuments((prevDocs) => {
          const updatedDocs = [...prevDocs, updatedDoc];
          localStorage.setItem("documents", JSON.stringify(updatedDocs));
          return updatedDocs;
        });
      };
      reader.readAsDataURL(file); // Read file as base64
      return null; // Return null to avoid unnecessary array element
    });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFileSelect = (id) => {
    setSelectedFiles((prevSelectedFiles) => {
      const updatedSelectedFiles = new Set(prevSelectedFiles);
      if (updatedSelectedFiles.has(id)) {
        updatedSelectedFiles.delete(id);
      } else {
        updatedSelectedFiles.add(id);
      }
      return updatedSelectedFiles;
    });
  };

  const handleFileDoubleClick = (document) => {
    if (document.type.startsWith("image/")) {
      const imageWindow = window.open();
      imageWindow.document.write(`
        <html>
        <head>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
            }
          </style>
        </head>
        <body>
          <img src="${document.content}" alt="${document.text}" />
        </body>
        </html>
      `);
    } else if (document.type.startsWith("application/pdf")) {
      const pdfWindow = window.open();
      pdfWindow.document.write(`
        <html>
        <head>
          <style>
            body {
              margin: 0;
            }
            embed {
              width: 100%;
              height: 100vh;
            }
          </style>
        </head>
        <body>
          <embed type="application/pdf" src="${document.content}" />
        </body>
        </html>
      `);
    } else if (document.type === "text/plain") {
      const textWindow = window.open();
      textWindow.document.write(`
        <html>
        <head>
          <style>
            body {
              font-family: 'Courier New', Courier, monospace;
              white-space: pre-wrap;
              padding: 20px;
            }
          </style>
        </head>
        <body>
          <pre>${document.content}</pre>
        </body>
        </html>
      `);
    } else if (document.type.startsWith("video/")) {
      const videoWindow = window.open();
      videoWindow.document.write(`
        <html>
        <head>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
            }
          </style>
        </head>
        <body>
          <video controls src="${document.content}" style="max-width: 100%; max-height: 100%;"></video>
        </body>
        </html>
      `);
    } else if (document.type.startsWith("audio/")) {
      const audioWindow = window.open();
      audioWindow.document.write(`
        <html>
        <head>
          <style>
            body {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;
              margin: 0;
            }
          </style>
        </head>
        <body>
          <audio controls src="${document.content}"></audio>
        </body>
        </html>
      `);
    } else if (document.type === "text/html") {
      const htmlWindow = window.open();
      htmlWindow.document.write(document.content);
    } else {
      const link = document.createElement("a");
      link.href = document.content;
      link.target = "_blank";
      link.click();
    }
  };

  const handleDownload = () => {
    selectedFiles.forEach((fileId) => {
      const file = documents.find((doc) => doc.id === fileId);
      if (file) {
        fetch(file.content)
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = file.text;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
          });
      }
    });
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleClickOutside = (event) => {
    if (
      !event.target.closest("#fileInput") &&
      containerRef.current &&
      !containerRef.current.contains(event.target) &&
      !event.target.closest(".document-card")
    ) {
      setSelectedFiles(new Set());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = () => {
    const updatedDocuments = documents.filter(
      (doc) => !selectedFiles.has(doc.id)
    );
    setDocuments(updatedDocuments);
    setFilteredDocuments(updatedDocuments);
    localStorage.setItem("documents", JSON.stringify(updatedDocuments));
    setSelectedFiles(new Set());
  };

  return (
    <div className="bg-[#f7f8f9] w-full md:w-full flex flex-col ">
      <div className="p-4 m-4 bg-white w-full md:w-auto rounded-lg space-y-10 md:space-y-5">
        <div className="flex flex-col sm:flex-row gap-y-5 justify-center sm:justify-between items-center">
          <div className="text-lg sm:text-xl md:ml-10 sm:0 font-bold ">
            My Documents
          </div>
          <div className="relative">
            <AiOutlineSearch className="absolute md:right-60 hidden md:block top-3 text-xl text-gray-900" />
            <input
              type="search"
              placeholder="Search Files..."
              className="px-3 py-2 md:pl-12 pl-5 ml-1 border rounded-2xl pr-10 sm:w-auto md:w-auto"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-y-5 sm:items-center justify-end">
          <div className="flex flex-wrap justify-center gap-y-5 items-center space-x-2">
            <label
              htmlFor="fileInput"
              className="bg-mygreen text-black font-bold px-8 py-2 rounded-lg cursor-pointer"
            >
              UPLOAD
            </label>
            <input
              type="file"
              id="fileInput"
              className="hidden"
              onChange={handleFileUpload}
              multiple
            />
            <button
              onClick={handleDownload}
              id="fileInput"
              className="bg-blue-500 text-white font-bold px-8 py-2 rounded-lg"
            >
              DOWNLOAD
            </button>
            <button
              onClick={handleDelete}
              id="fileInput"
              className="bg-red-500 text-white font-bold px-8 py-2 rounded-lg"
            >
              DELETE
            </button>
            <span onClick={handleCalendarToggle}>
              <BiCalendar className="text-blue-500 text-5xl cursor-pointer" />
            </span>
            {showCalendar && <DatePicker onChange={handleDateChange} />}
            <div className="p-1">
              <Link to="/homepage/settings">
                <VscKebabVertical className="text-xl" />
              </Link>
            </div>
          </div>
        </div>

        <div className="py-5 flex flex-wrap justify-center w-full mx-auto gap-5 justify-items-center">
          {filteredDocuments.length > 0 ? (
            filteredDocuments.map((document) => (
              <div
                ref={containerRef}
                key={document.id}
                className={`document-card ${
                  selectedFiles.has(document.id)
                    ? "border-2 border-blue-400 bg-blue-200"
                    : "border-white border-2"
                } flex flex-col items-center cursor-pointer w-56 justify-center py-5 `}
                onClick={() => handleFileSelect(document.id)}
                onDoubleClick={() => handleFileDoubleClick(document)}
              >
                {document.type.startsWith("image/") ? (
                  <div className="">
                    <img
                      src={document.content}
                      alt={document.text}
                      className="w-24 h-24 object-cover"
                    />
                  </div>
                ) : document.type.startsWith("application/pdf") ? (
                  <BsFiletypePdf className="xs:size-24 text-red-600" />
                ) : document.type.startsWith("video/") ? (
                  <FaFileVideo className="xs:size-24 text-blue-600" />
                ) : document.type.startsWith("audio/") ? (
                  <FaFileAudio className="xs:size-24 text-blue-600" />
                ) : document.type.startsWith("text/plain") ? (
                  <IoDocumentTextOutline className="xs:size-24 text-blue-600" />
                ) : (
                  <IoFolderSharp className="xs:size-24 text-mygreen" />
                )}
                <p className="text-black xs:text-xl font-bold">
                  {truncateText(document.text, 15)}
                </p>
                <p className="text-gray-400 text-sm font-bold">
                  {document.date}
                </p>
              </div>
            ))
          ) : (
            <div className="text-gray-500 text-2xl font-bold col-span-full py-10">
              No files found
            </div>
          )}
        </div>
      </div>
      {/* <div className="flex justify-center items-center sm:block">
        <button
          type="submit"
          className="bg-blue-500 rounded-md font-semibold px-10 text-white py-2 sm:ml-8 my-5"
        >
          LOAD MORE
        </button>
      </div> */}  

      <Outlet />
    </div>
  );
}
