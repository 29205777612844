import React, { useState, useEffect } from "react";
import background1 from "../../assets/background1.png";
import background2 from "../../assets/background2.png";
import background3 from "../../assets/background3.jpg";
import leftBanner from "../../assets/template.jpg";
import RioLogo from "../../assets/rio.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const rioApiUrl = process.env.REACT_APP_API_URL;
  const [backgroundIndex, setBackgroundIndex] = useState(0);

  const history = useNavigate();
  const backgrounds = [background1, background2, background3];
  const [email_mobile, setEmailMobile] = useState("");
  const [verification_code, setVerificationCode] = useState("");
  const [activeButton, setActiveButton] = useState("login");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [formStep, setFormStep] = useState(0);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    if (button === "signup") {
      setForgotPassword(1);
    }
  };
  const [forgotPassword, setForgotPassword] = useState(1);

  const initialData = {
    name: "",
    email: "",
    referralCode: "",
    mobile: "",
    country: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
  };

  const [formData, setFormData] = useState(initialData);

  const submitForm = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const validationError = validateForm();
      if (validationError) {
        // Show toast for validation error
        toast.error(validationError);
        return;
      }
      const response = await axios.post(`${rioApiUrl}/user/register`, formData);

      console.log("Form Data:", formData);

      if (response.status === 200) {
        const { token, user_detail } = response.data;
        const { id } = user_detail;
        localStorage.setItem("id", id);
        localStorage.setItem("token", token);
        console.log("Form data saved successfully!");

        const storedUserId = localStorage.getItem("id");
        const storedToken = localStorage.getItem("token");

        console.log("Stored User ID:", storedUserId);
        console.log("Stored Token:", storedToken);

        toast.success("Registration successful!");
        history("/homepage/dashboard");
      } else {
        console.error("Failed to save form data.");
        toast.error("Registration failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const [error, setError] = useState("");
  const getOtp = async (e) => {
    try {
      e.preventDefault();

      if (!/^\d{10,13}$/.test(email_mobile)) {
        setError("Please enter a valid mobile number");
        return;
      } else {
        setError("");
      }
      setLoading(true);
      const response = await fetch(`${rioApiUrl}/user/request-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email_mobile }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data", data);

        setOtp(data.otp);
        toast.success("OTP sent successfully");
        setForgotPassword(2);
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
    } finally {
      setLoading(false);
    }
  };
  const validateForm = () => {
    // Basic validation: Check if required fields are not empty
    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.mobile.trim() ||
      !formData.country.trim()
      // Add more validation conditions as needed
    ) {
      return "Please fill in all required fields.";
    }

    // Validate mobile number format
    if (!/^\d{10,13}$/.test(formData.mobile)) {
      return "Please enter a valid 10-digit mobile number.";
    }

    // Return null if validation passes
    return null;
  };
  const submitClick = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      const response = await axios.post(`${rioApiUrl}/user/check-otp`, {
        email_mobile,
        verification_code,
      });

      if (response.status === 200) {
        const user_type = response.data.user_type;

        if (user_type === "new") {
          setActiveButton("signUp");
          setFormStep(1);
          setForgotPassword(0);
          toast.success("OTP verification successful!");
        } else {
          const { user_type, token, existing_user } = response.data;
          const { id } = existing_user;

          localStorage.setItem("user_type", user_type);
          localStorage.setItem("id", id);
          localStorage.setItem("token", token);

          const storedUserType = localStorage.getItem("user_type");
          const storedUserId = localStorage.getItem("id");
          const storedToken = localStorage.getItem("token");

          console.log("Stored User Type:", storedUserType);
          console.log("Stored User ID:", storedUserId);
          console.log("Stored Token:", storedToken);

          history("/homepage/dashboard");
          toast.success("OTP verification successful!");
        }
      } else {
        console.error("OTP verification failed.");
        toast.error(
          "OTP verification failed. Please check your OTP and try again."
        );
      }
    } catch (error) {
      console.error("Error checking OTP:", error);
      toast.error("An error occurred while verifying OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  console.log("form step", formStep);

  const handleNextClick = () => {
    setFormStep((prevStep) => prevStep + 1);
  };

  const handleGoBackClick = () => {
    setFormStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    const changeBackground = () => {
      setBackgroundIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    };

    const intervalId = setInterval(changeBackground, 3000);

    return () => clearInterval(intervalId);
  }, []);
  const handleInputChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <div className="relative mt-0 mb-0 h-screen sm:h-screen xl:h-[100vh]">
        <div className="relative w-full h-full  md:overflow-hidden ">
          <img
            src={backgrounds[backgroundIndex]}
            className="w-full h-full  object-cover object-center transition-opacity duration-1000 opacity-60"
            alt={`Background ${backgroundIndex + 1}`}
          />

          <div className="absolute inset-0 flex  items-center p-10 justify-center">
            <div className="">
              <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{
                  fontSize: "14px",
                  minHeight: "50px",
                  borderRadius: "8px",
                }}
              />
            </div>

            <div className="text-white  text-4xl font-bold w-[800px]  flex sm:flex-row flex-col md:h-full justify-center items-center sm:justify-normal sm:items-start">
              <div className="sm:w-1/2 w-full  md:h-full relative sm:rounded-tl-2xl sm:rounded-bl-2xl overflow-hidden md:shadow-xl flex flex-col justify-center items-center sm:rounded-t-none rounded-t-xl">
                <img
                  src={leftBanner}
                  alt="Left Banner"
                  className="w-full h-full object-cover hidden md:block"
                />

                <div className="absolute hidden md:block  top-0 pt-14">
                  <Link to="https://riofin.whytap.tech/">
                    {" "}
                    <img
                      src={RioLogo}
                      alt="Riofin Logo"
                      className="item-center "
                    />
                  </Link>
                </div>
                <div className="absolute hidden md:block  bottom-10 mx-1">
                  <p className="text-white text-center text-base">
                    Unlock your financial potential with RioFin, your trusted
                    partner in smart investments.
                  </p>
                </div>
              </div>

              <div className="sm:w-1/2 w-full bg-white h-full sm:rounded-tr-2xl sm:rounded-br-2xl  overflow-hidden shadow-xl sm:rounded-b-none rounded-b-xl">
                {loading && (
                  <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                )}
                <Link
                  to="https://riofin.whytap.tech/"
                  className="flex justify-center"
                >
                  <img
                    src={RioLogo}
                    alt="Riofin Logo"
                    className="lg:hidden md:hidden"
                  />
                </Link>

                {forgotPassword === 1 && (
                  <div className="flex justify-end mx-6 mt-4">
                    <div className="absolute mr-[90px]">
                      <button
                        className={`ease-out font-bold text-lg py-1 px-4 
                    ${
                      activeButton === "login"
                        ? "bg-black absolute text-white border-b-2 border-black  z-10"
                        : "bg-mygreen text-black z-0"
                    } rounded-md ml-4 relative`}
                        onClick={() => handleButtonClick("login")}
                      >
                        Log In
                      </button>
                    </div>
                    <div className="relative">
                      <button
                        className={`ease-out font-bold text-lg py-1 px-4 
                      ${
                        activeButton === "signup"
                          ? "bg-black absolute text-white border-b-2 border-black z-10"
                          : "bg-mygreen text-black z-0"
                      } rounded-md ml-4 relative `}
                        onClick={() => handleButtonClick("signup")}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}

                {activeButton === "login" ? (
                  <form className="flex flex-col h-full p-8 ">
                    {forgotPassword === 1 && (
                      <>
                        <div className="md:pt-24 pt-auto">
                          <label
                            className="block text-gray-700 md:text-xl text-[16px] font-medium whitespace-nowrap"
                            htmlFor="mobileNumber"
                          >
                            Enter your registered phone number
                          </label>

                          <input
                            className="shadow-none mt-8 text-base font-normal appearance-none py-2 border-b border-gray-500 w-full px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 placeholder-sm:text-sm"
                            id="mobileNumber"
                            type="number"
                            placeholder="Enter Your Phone Number"
                            value={email_mobile}
                            onChange={(e) => setEmailMobile(e.target.value)}
                            required
                          />
                        </div>
                        {error && (
                          <p className="text-red-500 text-sm mt-2">{error}</p>
                        )}

                        <div className="flex justify-center pt-5">
                          <button
                            className="text-white font-bold text-sm border rounded-lg bg-black px-5 py-2"
                            onClick={getOtp}
                          >
                            Continue
                          </button>
                        </div>
                        <div className="flex justify-center pt-5 text-black text-[14px] text-normal flex-wrap">
                          <p>
                            Don't have an account?{" "}
                            <span
                              onClick={() => handleButtonClick("signup")}
                              className="cursor-pointer"
                            >
                              SignUp
                            </span>
                          </p>
                        </div>
                      </>
                    )}

                    {forgotPassword === 2 && (
                      <>
                        <div className="md:pt-20">
                          <h1 className="text-black text-xl text-center ">
                            Enter OTP
                          </h1>
                          <input
                            className=" pt-14 shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="otp"
                            type="number"
                            placeholder="Enter your OTP"
                            value={verification_code}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                            required
                          />

                          <div className="flex justify-center pt-10">
                            <button
                              className="bg-black text-white font-bold text-lg py-2 px-5 rounded-lg "
                              onClick={submitClick}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                ) : (
                  <form className="flex flex-col h-full p-8">
                    {forgotPassword === 1 && (
                      <>
                        <div className="md:pt-24 pt-auto">
                        <label
                            className="block text-gray-700 md:text-xl text-[16px] font-medium whitespace-nowrap"
                            htmlFor="mobileNumber"
                          >
                            Enter your phone number
                          </label>

                          <input
                            className="shadow-none mt-8 text-base font-normal appearance-none py-2 border-b border-gray-500 w-full px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500 placeholder-sm:text-sm"
                            id="mobileNumber"
                            type="number"
                            placeholder="Enter Your Phone Number"
                            value={email_mobile}
                            onChange={(e) => setEmailMobile(e.target.value)}
                            required
                          />
                        </div>

                        <div className="flex justify-center pt-5">
                          <button
                            className="text-white font-bold text-sm border rounded-lg bg-black px-5 py-2"
                            onClick={getOtp}
                          >
                            Continue
                          </button>
                        </div>
                        <div className="flex justify-center pt-5 text-black text-[14px] text-normal flex-wrap">
                          <p>
                            Already have an account?{" "}
                            <span
                              onClick={() => handleButtonClick("login")}
                              className="cursor-pointer"
                            >
                              Login
                            </span>
                          </p>
                        </div>
                      </>
                    )}

                    {forgotPassword === 2 && (
                      <>
                        <div className="md:pt-20">
                          <h1 className="text-black text-xl text-center ">
                            Enter OTP
                          </h1>
                          <input
                            className=" pt-14 shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="otp"
                            type="number"
                            placeholder="Enter your OTP"
                            value={verification_code}
                            onChange={(e) =>
                              setVerificationCode(e.target.value)
                            }
                            required
                          />
                          <div className="flex justify-center pt-10">
                            <button
                              className="bg-black text-white font-bold text-lg py-2 px-5 rounded-lg "
                              onClick={submitClick}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    <div>
                      {formStep === 1 && (
                        <div className="md:mt-4">
                          <label
                            className="text-gray-700 text-xl  font-medium mb-0"
                            htmlFor="name"
                          >
                            Name
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="email"
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />

                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="referralCode"
                          >
                            Referral Code (Optional)
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="referralCode"
                            type="text"
                            name="referralCode"
                            placeholder="Enter Your Referral Code"
                            value={formData.referralCode}
                            onChange={handleInputChange}
                          />

                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="otp"
                          >
                            Mobile Number
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="otp"
                            type="number"
                            name="mobile"
                            placeholder="Enter Your Mobile Number"
                            value={formData.mobile}
                            onChange={handleInputChange}
                            required
                          />
                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="state"
                          >
                            Country
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="state"
                            type="text"
                            placeholder="Enter Your State"
                            name="country"
                            value={formData.country}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      {formStep === 2 && (
                        <div className="md:mt-4">
                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="state"
                          >
                            State
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="state"
                            type="text"
                            placeholder="Enter Your State"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                          />

                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="city"
                          >
                            City
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="city"
                            type="text"
                            placeholder="Enter Your City"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                          />

                          <label
                            className="block text-gray-700 text-xl font-medium mb-1"
                            htmlFor="address"
                          >
                            Address
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="address"
                            type="text"
                            name="address"
                            placeholder="Enter Your Address"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                          />

                          <label
                            className="block text-gray-700 text-xl  font-medium mb-1"
                            htmlFor="pincode"
                          >
                            Pincode
                          </label>
                          <input
                            className="shadow-none text-base font-normal appearance-none border-b border-gray-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                            id="pincode"
                            type="number"
                            name="pincode"
                            placeholder="Enter Your Pincode"
                            value={formData.pincode}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      )}
                    </div>

                    {formStep === 1 && (
                      <div className="flex pt-4 justify-end ">
                        <div className="flex  ">
                          <button
                            onClick={handleNextClick}
                            className="text-white font-bold text-sm border rounded-lg bg-black px-5 py-2"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}

                    {formStep === 2 && (
                      <div className="flex gap-4 pt-4 justify-end">
                        <button
                          onClick={handleGoBackClick}
                          className="text-white font-bold text-sm border rounded-lg bg-black px-5 py-2"
                        >
                          Go back
                        </button>
                        <Link to="/">
                          <button
                            className="text-white font-bold text-sm border rounded-lg bg-black px-5 py-2"
                            onClick={submitForm}
                          >
                            Signup
                          </button>
                        </Link>
                      </div>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
