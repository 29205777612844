import React, { useEffect, useState } from "react";
import inv from "../../../assets/inv.png";
import { FaDollarSign } from "react-icons/fa6";
import { FaRupeeSign, FaStar } from "react-icons/fa";
import { Flex, Progress, Slider, Typography, Modal } from "antd";
import Money from "../../../assets/money.png";
import razo from "../../../assets/rarzor.png";
import upi from "../../../assets/upi.png";
import { BsBank } from "react-icons/bs";
import { AiFillBank } from "react-icons/ai";
import { GrDocumentUpload } from "react-icons/gr";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";

const { Dragger } = Upload;

function InvestNow() {
  const [ContinueButton, SetContinue] = useState(false);
  const [ProceedButton, SetProceed] = useState(false);
  const [SubmitButton, Setsubmit] = useState(false);
  const datas = [
    {
      type: "Real Estate",
      mode: "CRE - I",
      palce: "Mappedu",
      price: "1.2 Crore",
      status: "Raised",
    },
  ];
  const [arr, setarr] = useState([]);
  useEffect(() => {
    const months = 48;
    console.log(months / 12);
    const arrs = [];
    const loop = async () => {
      for (let i = months / 12; i > 0; i--) {
        await arrs.push(<div>Year{i}</div>);
      }
    };
    loop();
    setarr(arrs);

    console.log(arrs);
  }, []);

  const [totInvest, settotInvest] = useState(100000);
  const [rate, setrate] = useState(0);
  const [period, setperiod] = useState(0);
  function handleinvestmetchange(e) {
    settotInvest(e);
  }
  function handlereturn(e) {
    setrate(e);
  }
  function handleperiod(e) {
    setperiod(e);
  }
  const config = {
    headers: {
      auth: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  function handleaxio() {
    axios
      .get("https://app-script.riofinassets.com/44", config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <div className="bg-gray-50 w-full p-5">
      <div className="w-full bg-white p-5  rounded-xl">
        <div className="w-full relative">
          <div className="bg-white w-44 flex justify-center flex-col  rounded-r-md absolute z-20 top-2 p-1 left-0 sm:p-3 bg-opacity-75">
            {datas &&
              datas.map((items) => (
                <div>
                  <p className="text-xs text-green-600 font-semibold">
                    {items.type}
                  </p>
                  <p className="text-xl font-semibold">{items.mode}</p>
                  <p className="text-sm font-semibold">{items.palce}</p>
                  <div>
                    <div className="flex items-center gap-2">
                      <ProgressBar
                        completed={1.2}
                        maxCompleted={10}
                        barContainerClassName="container"
                        completedClassName="barCompleted"
                        labelClassName="label"
                        className="w-2/5"
                      />
                      <p className="text-sm">₹ 1.2 Crore</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="parents flex md:flex-row flex-col h-fit  md:h-[450px]">
            <div className="md:w-[60%] w-full h-full z-10">
              <img src={inv} className="w-full h-full"></img>
            </div>
            <div className="bg-gradient-to-b rounded-xl -mt-1 md:-mt-0 md:-ml-1 flex flex-col from-green-100 via-green-50 to-green-50 w-full md:w-[40%] h-full">
              <div className="flex flex-col mt-3  gap-2">
                <p className="pl-10 text-2xl font-bold">Invest Now</p>
                <p className="w-36 h-[2px] bg-green-600"></p>
              </div>
              <div className="md:px-10 sm:px-6 px-3 flex flex-col gap-2 p-4 w-full">
                <div className="w-full">
                  <div className="h-10 rounded-lg bg-white border border-mygreen w-full p-1 flex gap-3  items-center ">
                    <div className="bg-mygreen  w-fit p-2 rounded-lg text-center">
                      <FaRupeeSign className="size-3 text-center" />
                    </div>
                    <input
                      type="text"
                      placeholder="32,200"
                      className="text-base placeholder:text-black px-2 h-full w-full font-semibold"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col gap-3">
                  <div className="flex items-center justify-between">
                    <p className="text-xs font-semibold">Total Investment</p>
                    <div className="px-6 py-[3px] flex items-center rounded-md bg-mygreen">
                      <FaRupeeSign className="text-xs" />
                      <p className="text-xs font-semibold">{totInvest}</p>
                    </div>
                  </div>
                  <div className="bg-gray-50 shadow-lg px-2 rounded-2xl">
                    <Slider
                      min={100000}
                      onChange={handleinvestmetchange}
                      value={totInvest}
                      max={1000000}
                      style={{ color: "green" }}
                    ></Slider>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <p className="text-xs font-semibold">Return rate (p.a)</p>
                    <div className="px-6 py-[3px] flex items-center rounded-md bg-mygreen">
                      <p className="text-xs font-semibold">{rate}%</p>
                    </div>
                  </div>
                  <div className="bg-gray-50 shadow-lg px-2 rounded-2xl">
                    <Slider
                      min={0}
                      onChange={handlereturn}
                      value={rate}
                      max={100}
                      style={{ color: "green" }}
                    ></Slider>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <p className="text-xs font-semibold">Time Period</p>
                    <div className="px-6 py-[3px] flex items-center rounded-md bg-mygreen">
                      <p className="text-xs font-semibold">{period}Yr</p>
                    </div>
                  </div>
                  <div className="bg-gray-50 shadow-lg px-2 rounded-2xl">
                    <Slider
                      min={0}
                      onChange={handleperiod}
                      value={period}
                      max={100}
                      style={{ color: "green" }}
                    ></Slider>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mb-3 md:mb-0 justify-center items-center">
                <div
                  onClick={handleaxio}
                  className="w-[80%] rounded-md  py-1 bg-[#2272FF] font-semibold sm:text-sm text-xs text-white cursor-pointer text-center"
                >
                  View Payment Schedule
                </div>
                <div
                  onClick={() => SetContinue(true)}
                  className=" py-1 active:shadow-2xl active:translate-y-1 bg-mygreen shadow-xl text-white font-semibold text-center sm:text-sm text-xs w-[60%] cursor-pointer rounded-2xl mx-auto"
                >
                  Pay Now
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='w-full h-[300px] rounded-xl border-2 border-[#2272FF]'> 
  <div className='p-5 flex flex-col'>
    
    <div className='flex w-full justify-end gap-5'>
        {arr && arr.map((year,index)=>(
            
            <div className='flex'>{year}</div>
        ))}
        </div>
        <div className='flex flex-row justify-between'>
            <p>test</p>
            <div className='flex'>
            <div>2</div>
            <div>2</div>
            <div>2</div>
            </div>
            
        </div>
  </div>
  </div> */}
      </div>
      <Modal
        onCancel={() => SetContinue(false)}
        open={ContinueButton}
        footer={false}
      >
        <div className="flex flex-col sm:p-2 mt-5 gap-5 w-full justify-center">
          <div className="flex justify-center items-center   w-full">
            <div className="w-[20%] z-10 h-14 sm:h-20 p-1 sm:p-2 bg-gray-300 shadow-inner rounded-lg">
              <img
                className="w-full h-full object-contain rounded-lg"
                src={Money}
              ></img>
            </div>
            <div className="md:text-lg text-sm font-semibold -ml-3 h-10 sm:h-14 text-center w-[80%] bg-blue-500 flex justify-center items-center">
              Choose Payment Option
            </div>
          </div>
          <div>
            <p className="text-center">You need to take a screenshot of the payment once you complete the payment.</p>
          </div>
          <div className="flex md:flex-row flex-col gap-3 sm:gap-5 justify-center  items-center">
            <div className=" cursor-pointer border border-mygreen w-[80%] sm:w-[60%]  md:w-fit h-10 md:h-8 flex justify-center items-center rounded-md shadow-lg p-1">
              {" "}
              <div className="flex gap-1 justify-center items-center">
                <AiFillBank className="size-5" />{" "}
                <p className="text-blue-950 font-bold ">Bank Transfer</p>
              </div>
            </div>
            <div className="cursor-pointer border border-mygreen w-[80%] sm:w-[60%]  md:w-28 h-10 md:h-8 rounded-md shadow-lg p-1">
              <img className="w-full object-contain h-full" src={razo}></img>
            </div>
            <div className="cursor-pointer border border-mygreen w-[80%]  sm:w-[60%]  md:w-28 h-10 md:h-8 rounded-md shadow-lg p-1">
              <img className="w-full object-contain h-full" src={upi}></img>
            </div>
          </div>
          <button
            onClick={() => {
              SetProceed(true);
              SetContinue(false);
            }}
            className="w-[60%] mb-1 bg-mygreen active:shadow-2xl active:translate-y-1 drop-shadow-lg duration-100 mx-auto py-1 rounded-xl text--lg font-semibold"
          >
            Proceed
          </button>
        </div>
      </Modal>
      <Modal
        open={ProceedButton}
        footer={false}
        onCancel={() => SetProceed(false)}
      >
        <div className="mt-10 main sm:px-8 px-5 w-full flex flex-col gap-5 ">
          <Dragger className="">
            <div className="flex sm:flex-row w-full h-full sm:gap-0 gap-3  flex-col-reverse justify-center items-center sm:justify-between">
              <div className="flex flex-col gap-1 items-center sm:items-start">
                <p className="text-sm">Upload Screenshot</p>
                <p className="text-xs">
                  Please upload a screenshot of the completed payment.
                </p>
              </div>
              <GrDocumentUpload className="size-10 icon font-thin text-blue-500" />
            </div>
          </Dragger>
          <button
            onClick={() => {
              Setsubmit(true);
              SetProceed(false);
            }}
            className="w-[60%]   bg-mygreen active:shadow-2xl active:translate-y-1 drop-shadow-lg duration-100  py-1 rounded-xl mx-auto font-semibold"
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        onCancel={() => Setsubmit(false)}
        open={SubmitButton}
        footer={false}
        width={400}
      >
        <div className="flex flex-col gap-2 p-5 mt-5 justify-center items-center">
          <p className="md:text-3xl text-2xl bg-gradient-to-r from-yellow-700 via-yellow-600 p-1 to-yellow-500 text-clip text-transparent bg-clip-text font-bold">
            Congratulations!
          </p>
          <p className="text-center text-base">
            Your investment has been successfully processed.
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default InvestNow;
