import React from 'react';
import Rectangle from '../../../assets/ImgAll.png';
import unique from '../../../assets/system-uicons_scale.png';



const Investments = () => {

 

  return (
    <div className='bg-white shadow-sm py-0.5 rounded-xl'>
     <div className="hidden lg:block  ">
  <div className=" gap-0">
    <h1 className="md:text-2xl text-[22px]  pl-5 font-sans font-bold  md:mt-5  md:text-left">
    Commercial Real Estate
    </h1>
  
  </div>
  {/* <hr className="md:ml-[650px] md:mt-4 md:mr-auto mt-3 md:mb-20 h-0.5 bg-mygreen hidden md:block" /> */}
  <div className='md:mr-[940px] md:mt-2 md:ml-auto mt-3 md:mb-4 h-0.5 bg-mygreen  hidden md:block' />
</div>


{/* Mobile heading */}
<div className="lg:hidden sm:block  px-8 lg:px-20 xl:px-32">
  <h1 className="md:text-4xl text-[22px] font-bold text-center mt-6"> Commercial Real Estate</h1>
 
  <hr className='bg-mygreen p-0.5 w-full' />
</div>

      <div className='md:relative' >

  {/* cards */}
  <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 flex-wrap justify-center ' >

    {/* card 1 */}
    <div className="bg-white md:max-w-sm shadow-lg m-5 z-[10]">
      {/* Image at the top */}
      <div className='relative'>
       <img src={Rectangle} alt="Image" className=" w-full " />
       <div className="flex absolute bottom-0 -mb-1 justify-center">
        <button className="bg-mygreen text-black text-[14px] drop-shadow-lg font-medium py-2 px-4">Fully Funded</button>
      </div>
      </div>
      

      {/* Centered button with green-500 background */}
      

      {/* Heading and content at the bottom */}
      <div className="mt-4 pl-6">
        <h2 className="text-[15px] font-semibold text-black">Rio Global V LLP</h2>
        <p className="text-gray-900 mt-1 font-normal text-[13px]">Near To Green Airport</p>
      </div>
      <hr className="h-0.5 my-5 md:mr-14 bg-black" />
      {/* Another button at the bottom */}
      <div className="mb-4 py-2 shadow-md w-2/4 justify-center flex ml-6">
        <img src={unique} className='w-4 h-4' />
        <button className="bg-white text-black text-[13px] px-2 rounded-full">4.5 Crore</button>
      </div>
    </div>

     {/* card 2 */}

    <div className="bg-white md:max-w-sm shadow-lg m-5 z-[10]">
      {/* Image at the top */}
      <div className='relative'>
       <img src={Rectangle} alt="Image" className=" w-full " />
       <div className="flex absolute bottom-0 -mb-1 justify-center">
        <button className="bg-mygreen text-black text-[14px] drop-shadow-lg font-medium py-2 px-4">Fully Funded</button>
      </div>
      </div>

      {/* Heading and content at the bottom */}
      <div className="mt-4 pl-6">
        <h2 className="text-[15px] font-semibold text-black">Rio Global VI LLP</h2>
        <p className="text-gray-900 mt-1 font-normal text-[13px]">Prime Location To ITC Warehouse</p>
      </div>
      <hr className="h-0.5 my-5 md:mr-14 bg-black" />
      {/* Another button at the bottom */}
      <div className="mb-4 py-2 shadow-md w-2/4 justify-center flex ml-6">
        <img src={unique} className='w-4 h-4' />
        <button className="bg-white text-black text-[13px] px-2 rounded-full">4.5 Crores</button>
      </div>
    </div>

 {/* card 3 */}
    <div className="bg-white md:max-w-sm shadow-lg   cover m-5 z-[10]">
      {/* Image at the top */}
      <div className='relative'>
       <img src={Rectangle} alt="Image" className=" w-full " />
       <div className="flex absolute bottom-0 -mb-1 justify-center">
        <button className="bg-mygreen text-black text-[14px] drop-shadow-lg font-medium py-2 px-4">Fully Funded</button>
      </div>
      </div>

      {/* Heading and content at the bottom */}
      <div className="mt-4 pl-6">
        <h2 className="text-[15px] font-semibold text-black">Rio Global VI LLP</h2>
        <p className="text-gray-900 font-normal text-[13px] mt-1">Near To New Metro</p>
      </div>
      <hr className="h-0.5 my-5 md:mr-14 bg-black" />
      {/* Another button at the bottom */}
      <div className="mb-4 py-2 shadow-md w-2/4 justify-center flex ml-6">
        <img src={unique} className='w-4 h-4' />
        <button className="bg-white text-black text-[13px] px-2 rounded-full">4.5 Crores</button>
      </div>
    </div>

 {/* card 4 */}
 <div className="bg-white md:max-w-sm  shadow-lg   cover m-5 z-[10]">
      {/* Image at the top */}
      <div className='relative'>
       <img src={Rectangle} alt="Image" className=" w-full " />
       <div className="flex absolute bottom-0 -mb-1 justify-center">
        <button className="bg-mygreen text-black text-[14px] drop-shadow-lg font-medium py-2 px-4">Fully Funded</button>
      </div>
      </div>

      {/* Heading and content at the bottom */}
      <div className="mt-4 pl-6">
        <h2 className="text-[15px] font-semibold text-black">Rio Global VI LLP</h2>
        <p className="text-gray-900 font-normal text-[13px] mt-1">Near To New Metro</p>
      </div>
      <hr className="h-0.5 my-5 md:mr-14 bg-black" />
      {/* Another button at the bottom */}
      <div className="mb-4 py-2 shadow-md w-2/4 justify-center flex ml-6">
        <img src={unique} className='w-4 h-4' />
        <button className="bg-white text-black text-[13px] px-2 rounded-full">4.5 Crores</button>
      </div>
    </div>

  </div>

  </div>



{/* Machinery Leasing */}
<div className="hidden lg:block  ">
  <div className=" gap-0">
    <h1 className="md:text-2xl text-[22px]  pl-5 font-sans font-bold  md:mt-10  md:text-left">
    Machinery Leasing
    </h1>
  
  </div>
  {/* <hr className="md:ml-[650px] md:mt-4 md:mr-auto mt-3 md:mb-20 h-0.5 bg-mygreen hidden md:block" /> */}
  <div className='md:mr-[940px] md:mt-2 md:ml-auto mt-3 md:mb-4 h-0.5 bg-mygreen  hidden md:block' />
</div>


{/* Mobile heading */}
<div className="lg:hidden sm:block  px-8 lg:px-20 xl:px-32">
  <h1 className="md:text-4xl text-[22px] font-bold text-center mt-6"> Machinery Leasing</h1>
 
  <hr className='bg-mygreen p-0.5 w-full' />
</div>

      <div className='md:relative' >

  {/* cards */}
  <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 flex-wrap justify-center ' >

    {/* card 1 */}
    <div className="bg-white md:max-w-sm shadow-lg  m-5 z-[10]">
      {/* Image at the top */}
      <div className='relative'>
       <img src={Rectangle} alt="Image" className=" w-full " />
       <div className="flex absolute bottom-0 -mb-1 justify-center">
        <button className="bg-mygreen text-black text-[14px] drop-shadow-lg font-medium py-2 px-4">Fully Funded</button>
      </div>
      </div>

      {/* Heading and content at the bottom */}
      <div className="mt-4 pl-6">
        <h2 className="text-[15px] font-semibold text-black">Rio Global V LLP</h2>
        <p className="text-gray-900 mt-1 font-normal text-[13px]">Near To Green Airport</p>
      </div>
      <hr className="h-0.5 my-5 md:mr-14 bg-black" />
      {/* Another button at the bottom */}
      <div className="mb-4 py-2 shadow-md w-2/4 justify-center flex ml-6">
        <img src={unique} className='w-4 h-4' />
        <button className="bg-white text-black text-[13px] px-2 rounded-full">4.5 Crores</button>
      </div>
    </div>

     {/* card 2 */}

    <div className="bg-white md:max-w-sm shadow-lg  m-5 z-[10]">
      {/* Image at the top */}
      <div className='relative'>
       <img src={Rectangle} alt="Image" className=" w-full " />
       <div className="flex absolute bottom-0 -mb-1 justify-center">
        <button className="bg-mygreen text-black text-[14px] drop-shadow-lg font-medium py-2 px-4">Fully Funded</button>
      </div>
      </div>

      {/* Heading and content at the bottom */}
      <div className="mt-4 pl-6">
        <h2 className="text-[15px] font-semibold text-black">Rio Global VI LLP</h2>
        <p className="text-gray-900 mt-1 font-normal text-[13px]">Prime Location To ITC Warehouse</p>
      </div>
      <hr className="h-0.5 my-5 md:mr-14 bg-black" />
      {/* Another button at the bottom */}
      <div className="mb-4 py-2 shadow-md w-2/4 justify-center flex ml-6">
        <img src={unique} className='w-4 h-4' />
        <button className="bg-white text-black text-[13px] px-2 rounded-full">4.5 Crores</button>
      </div>
    </div>

 
  </div>

  </div>

    </div>
  )
}

export default Investments