import React, { useState } from 'react';
import Snapshot from './Snapshot';
import RequestReport from './RequestReport';
import ReferredList from './ReferredList';
import ReferEarn from './Refer&Earn';


const Referral = () => {
    const [data, setData] = useState({
        TotalSuccess: { value: 2000000, percentage: 121 },
        TotalPending: { value: 20173, percentage: 0.05 },
        TotalFailed: { value: 300000, percentage: 18 },
    });

    const [showActiveContent, setShowActiveContent] = useState('Refferal(Snapshot)');

    const toggleContent = (content) => {
        setShowActiveContent(content);
    };

    return (
        <div className='bg-gray-100 p-3'>
                        <div className='flex flex-row md:gap-3 gap-1  md:pl-10 p-4'>
                {/* <button onClick={() => toggleContent('Refer&Earn')} className={`focus:outline-none font-bold sm:text-xl text-xs ${showActiveContent === 'Refer&Earn' ? 'text-[#2272FF] border-b-2 border-[#2272FF]' : ''}`}>
                    Refer & Earn
                </button >
                <p>|</p> */}
                <button onClick={() => toggleContent('Refferal(Snapshot)')} className={`focus:outline-none font-bold sm:text-xl text-xs ${showActiveContent === 'Refferal(Snapshot)' ? 'text-[#2272FF] border-b-2 border-[#2272FF]' : ''}`}>
                    Referral (Snapshot)
                </button >
                <p>|</p>
                <button onClick={() => toggleContent('Request Report')} className={`focus:outline-none font-bold sm:text-xl text-xs ${showActiveContent === 'Request Report' ? 'text-[#2272FF] border-b-2 border-[#2272FF]' : ''}`}>
                    Request Report
                </button >
                <p>|</p>
                <button onClick={() => toggleContent('Referred List')} className={`focus:outline-none  font-bold sm:text-xl text-xs ${showActiveContent === 'Referred List' ? 'text-[#2272FF] border-b-2 border-[#2272FF]' : ''}`}>
                    Referred List
                </button >
            </div >
            <div>
                {/* {showActiveContent === 'Refer&Earn' && <ReferEarn />} */}
                {showActiveContent === 'Refferal(Snapshot)' && <Snapshot />}
                {showActiveContent === 'Request Report' && <RequestReport />}
                {showActiveContent === 'Referred List' && <ReferredList />}
            </div>
        </div >
    );
}

export default Referral;
