import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const rioApiUrl = process.env.REACT_APP_API_URL;

const BlogPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    // axios.get(`${rioApiUrl}/user/blog-view/${id}`)
    axios.get(`${rioApiUrl}/user/blogs-view/${id}`)
      .then((res) => setBlog(res.data.blog))
      .catch((err) => console.log(err));
  }, [id]);


  return (
    <div className="px-4 lg:px-10 mt-5 xl:px-20 mb-4">
      <div className="mb-8">
        <h1 className="text-blue font-bold text-2xl mb-4">{blog.heading}</h1>
        <p className="text-gray-500 text-xl">{blog.author}</p>
        <img
                className="  rounded-t-lg md:w-full h-fit  w-full mt-0.5 "
                src={blog.img_url}
                alt={`Card`}
              />
      </div>
      <div dangerouslySetInnerHTML={{ __html: blog.description }} className="text-justify text-red mt-4" style={{ fontSize: '20px' }}></div>

    </div>
  );
};

export default BlogPage;


    // <div className="px-4 lg:px-10 mt-5 xl:px-20">
    //   <div className="mb-8">
    //     <h1 className="text-blue font-bold text-2xl">Top 5 Alternate Investments Millennials Will Explore in 2024</h1>
    //     <p className="text-justify mt-4">
    //       As the financial landscape evolves, millennials are becoming increasingly discerning investors, seeking alternative avenues beyond traditional asset classes. In the quest for diversification, growth potential, and risk-adjusted returns, millennials are exploring unconventional investments. Individuals have access to a wealth of information from diverse channels such as blogs, social media platforms, podcasts, YouTube, and more. Many of them carefully evaluate whether their investments align with their financial objectives and match their personal risk tolerance.
    //     </p>
    //   </div>
      
    //   <div className="mb-8">
    //     <h1 className="text-3xl font-bold">Why are Millennials Investing in Alternative Assets?</h1>
    //     <p className="text-justify mt-4">
    //       Millennials are increasingly gravitating towards alternative assets as they seek investment avenues beyond the conventional options of stocks, bonds, and cash. The appeal of alternative investments lies in their ability to provide a distinctive suite of advantages, including diversification, growth potential, and risk-adjusted returns. By venturing into alternative assets, investors can access private company valuations, opening doors to higher growth opportunities and lower cost structures. This unique characteristic often translates into superior risk-adjusted and nominal returns compared to more traditional investments available in public markets. Millennials are drawn to the potential for enhanced profitability and capital appreciation that alternative assets can offer. Furthermore, the lower correlation of alternative assets to public markets is a key attraction for millennials. This quality acts as a hedge against inflation, shielding investment portfolios from the impact of market fluctuations. Additionally, alternative assets often generate a reliable stream of passive income, adding a layer of stability and predictability to the overall investment strategy.
    //     </p>
    //   </div>
      
    //   <div className="mb-8">
    //     <h1 className="text-3xl font-bold">Top 5 Alternate Investments Millennials Will Explore in 2024</h1>
    //     <p className="mt-4 text-xl">1. Real Estate: A Timeless Investment</p>
    //     <p className="text-justify mt-4">
    //       Real estate stands as a perennial favorite among alternative investments, and its allure remains undiminished in 2024. Millennials recognize its potential to diversify portfolios, reduce volatility, and amplify returns through leveraged capital appreciation. In an era marked by inflation and market fluctuations, real estate continues to shine. Investment properties, deriving their value from rent-based multiples, exhibit resilience as rents increase due to inflation, consequently boosting the overall property value. In 2024, millennials are likely to focus on physical properties like residential homes and commercial spaces. Additionally, Real Estate Investment Trusts (REITs) provide an avenue for investing in real estate without the burden of physical property ownership. REITs offer regular income streams through dividends, making them an attractive option for income-conscious millennials.
    //     </p>
    //   </div>
    // </div>
