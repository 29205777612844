import React, { useState } from 'react';
import Personalkyc from './Personalkyc';
import Bankkyc from './Bankkyc';

const KYC = () => {
    const [showActiveContent, setShowActiveContent] = useState('Personalkyc');

    const toggleContent = (content) => {
        setShowActiveContent(content);
    };

    return (
        <div className='bg-gray-100 p-3'>
            <div className='flex flex-row    md:pl-10 p-4'>
                <button onClick={() => toggleContent('Personalkyc')} className={`focus:outline-none rounded-lg px-10 text-md md:py-3 py-2 text-white text-center bg-blue-500  font-bold  ${showActiveContent === 'Personalkyc' ? 'bg-mygreen text-white border-b-2 rounded-lg px-10 border-mygreen' : ''}`}>
                    Personal Kyc
                </button>
               
                <button onClick={() => toggleContent('Bankkyc')} className={`focus:outline-none rounded-lg px-10 text-md bg-blue-500 text-white  font-bold  ${showActiveContent === 'Bankkyc' ? 'bg-mygreen text-white border-b-2 rounded-lg px-10 border-mygreen' : ''}`}>
                    Bank Kyc
                </button>
            </div>
            <div>
                {showActiveContent === 'Personalkyc' && <Personalkyc />}
                {showActiveContent === 'Bankkyc' && <Bankkyc />}
            </div>
        </div>
    );
}

export default KYC;
