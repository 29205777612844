// PrivateRoute.js

import { Outlet, Navigate } from "react-router-dom";

const PrivateRoute = () => {
  const token = localStorage.getItem("token");
  console.log("token :", token);

  let auth = { token: token };

  return auth.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
