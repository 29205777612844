import React, { useState, useEffect } from "react";
import earn from "../../../assets/earn.png";
import { FaPlayCircle, FaRegCopy } from "react-icons/fa";
import yourThumbnailImage from "../../../assets/video.png";
import { MdCelebration } from "react-icons/md";
import "./Video.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiLogoWhatsapp } from "react-icons/bi";
import { Modal } from "antd";
import { GiCheckMark } from "react-icons/gi";

const rioApiUrl = process.env.REACT_APP_API_URL;

const Snapshot = () => {
  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const boxesData = [
    { title: "2 Crores", content: "Paid to affiliates in the past year alone" },
    { title: "104", content: "New investors onboarded through referrals" },
    { title: "24/7", content: "Commission payments issued to affiliates" },
    { title: "24/7", content: "Commission payments issued to affiliates" },
  ];

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(referralData.referral_code)
      .then(() => {
        setCopySuccess(true);
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        toast.error("Failed to copy to clipboard");
      });
  };

  const handlePlayClick = () => {
    setVideoPlaying(true);
  };
  const formatNumberWithIndianSystem = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const [data, setData] = useState({
    commision: { text: "Commission Earned", value: 4500000 },
    balance: { text: "Balance Amount", value: 2500000 },
  });

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      commision: {
        ...prevData.commision,
        value: formatNumberWithIndianSystem(prevData.commision.value),
      },
      balance: {
        ...prevData.balance,
        value: formatNumberWithIndianSystem(prevData.balance.value),
      },
    }));
  }, []);

  const [referralData, setReferralData] = useState({
    earned_amount: 0,
    settled_amount: 0,
    pending_amount: 0,
    my_referals: [],
    referral_code: "",
  });

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("Authentication token not found.");
        }

        const response = await axios.get(`${rioApiUrl}/user/referal/show`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setReferralData(response.data);
      } catch (error) {
        console.error("Error fetching referral data:", error);
      }
    };

    fetchReferralData();
  }, []);

  const Box = ({ title, content }) => (
    <div className="m-4 p-4 border xs:mx-0 mx-auto border-mygreen rounded text-center shadow-lg xs:w-48 w-full bg-gray-50">
      <h2 className="text-lg font-bold mb-2">{title}</h2>
      <p className="text-md font-semibold">{content}</p>
    </div>
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* second section */}
      <div className="p-6 flex flex-col w-full items-center">
        <div className="p-5 bg-white w-full md:shadow-xl rounded-xl mb-5 flex lg1:flex-row flex-col justify-center items-center">
          <div className="lg:flex lg1:w-3/5 w-full  bg-white gap-0">
            <div className="shadow-xl lg1:bg-white lg1:rounded-xl lg1:flex gap-0 mb-5 md:ml-5 md:mr-5 relative z-10">
              <div className="md:p-10 p-5   md:bg-white lg1:shadow-md lg1:rounded-md">
                <div className="grid justify-evenly items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6">
                  <div className=" border-2 w-full border-mygreen rounded-md justify-between items-center mb-2">
                    <p className="text-lg ml-2 text-[#838383] font-semibold">
                      Earned Amount
                    </p>
                    <p className="text-md ml-2 font-bold">
                      {formatNumberWithIndianSystem(referralData.earned_amount)}
                    </p>
                  </div>
                  <div className="border-2 w-full border-mygreen rounded-md justify-between items-center mb-2">
                    <p className="text-lg ml-2 text-[#838383] font-semibold">
                      Settled Amount
                    </p>
                    <p className="text-md ml-2 font-bold">
                      {formatNumberWithIndianSystem(
                        referralData.settled_amount
                      )}
                    </p>
                  </div>
                  <div className="border-2 w-full border-mygreen rounded-md justify-between items-center mb-2">
                    <p className="text-lg ml-2 text-[#838383] font-semibold">
                      Pending Amount
                    </p>
                    <p className="text-md ml-2 font-bold">
                      {formatNumberWithIndianSystem(
                        referralData.pending_amount
                      )}
                    </p>
                  </div>
                </div>
                <h3 className="text-[#2272FF] lg:pt-10 md:pt-10 text-2xl font-bold">
                  Withdraw your amount
                </h3>
                <div className="flex justify-start pt-5">
                  {" "}
                  <div className="flex justify-end relative items-center">
                    <input
                      type="number"
                      placeholder=""
                      className="p-1 rounded-md w-full bg-[#f5f5f5] font-bold border-2 sm:py-4   lg:pr-32"
                    />
                    <button
                      onClick={showModal}
                      className="absolute text-white bg-[#2272FF] p-1 text-xl rounded-md md:px-4  sm:h-14 font-bold"
                    >
                      Request
                    </button>
                  </div>
                </div>
                <p className="mt-5 text-black md:text-sm">
                  To transfer your referral earnings from your Vault to your
                  Bank account, simply submit a Withdrawal request.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg1:w-2/5 w-full md:ml-0 md:mr-0 px-3 py-3 space-y-2 ml-0 mr-0 shadow-xl h-[420px] justify-evenly items-center bg-white rounded-md md:p-4">
            <img src={earn} alt="" className="h-[190px]" />
            <h1 className="font-bold text-center">Refer & Get Rewarded</h1>
            <p className="text-center text-sm ">
              For every investor that invests
              <br />
              from your referral, you will be paid 2%
            </p>

            <div className="bg-[#2272FF] sm:mx-0 mx-3 flex md:flex-row flex-col justify-evenly items-center  rounded-md">
              <h1 className="text-white mx-2 text-sm">Referral ID</h1>
              <div className="flex justify-end relative items-center p-1 md:pl-8">
                <input
                  type="text"
                  value={referralData.referral_code}
                  readOnly
                  className="p-1 border-2 w-full border-mygreen rounded-md"
                />
                <FaRegCopy
                  className="absolute text-gray-400 mx-3 cursor-pointer"
                  onClick={handleCopyClick}
                />
              </div>
            </div>
            <div className="">
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>

            <div className="flex lg:flex-row md:flex-row sm:flex-row flex-col ">
              <a
                href="https://wa.me/1234567890"
                target="_blank"
                rel="noopener noreferrer"
                className="flex justify-evenly items-center border-2 border-mygreen bg-[#DCFFD6] p-1 gap-2 rounded-md"
              >
                <BiLogoWhatsapp className="text-white bg-mygreen rounded-xl p-1 size-5" />
                Whatsapp
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="p-5  bg-white grid grid-cols-1 sm:grid-cols-1 rounded-xl  ml-5 mr-5  shadow-xl md:grid-cols-2 lg:grid-cols-2 gap-6 ">
        <div className="flex flex-col justify-center items-start">
          <div className="flex justify-center items-center">
            <h1 className="font-bold lg:text-5xl md:text-2xl text-lg bg-gradient-to-b from-[#ffdb03] to-[#ffbb0b] bg-clip-text text-transparent xs:p-5 p-2">
              Congratulation
            </h1>
            <MdCelebration className="xs:size-12 size-5 text-yellow-400 " />
          </div>
          <p className="xs:pl-5 pl-2 text-xl md:text-2xl font-semibold text-black">
            Now you can access the
            <br /> Referral Rewards
          </p>
        </div>
        {!isVideoPlaying ? (
          <>
            <div className="relative">
              <img
                src={yourThumbnailImage}
                alt="Video Thumbnail"
                className="w-full h-full object-cover cursor-pointer  border-mygreen border-r-8 border-b-8 rounded-[26px]"
                onClick={handlePlayClick}
              />
              <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-[50%] left-[50%]">
                <FaPlayCircle
                  className="w-14 h-14 text-white cursor-pointer"
                  onClick={handlePlayClick}
                />
              </div>
              <h1 className="absolute bottom-5 left-2 text-white md:text-2xl text-sm font-semibold">
                {" "}
                How to use the referral section - Demo
              </h1>
            </div>
          </>
        ) : (
          <div className="iframe-container ">
            <iframe
              title="YouTube video player"
              width="786"
              height="417"
              object-fit="cover"
              className="rounded-lg"
              src="https://www.youtube.com/embed/nw9t2xx3A4k?si=DDLO51wKe6RLmmPB"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-evenly  bg-white p-5 m-5 rounded-2xl">
        {boxesData.map((box, index) => (
          <Box key={index} {...box} />
        ))}
      </div>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className=""
      >
        <div className="flex flex-col justify-center items-center pt-10 space-y-4">
          <GiCheckMark className="size-10 text-green-500" />
          <p className=" text-center px-10">
            Your request is being processed and will be completed within the
            standard processing time. You will receive a confirmation once the
            transaction is finalized.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Snapshot;
