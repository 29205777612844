import React, { useState, useEffect } from "react";
import sidelogo from "../../assets/Logo (2).png";
import ternari from "../../assets/Icon.png";
import { FaHome } from "react-icons/fa";
import { BiSolidCreditCard, BiSupport } from "react-icons/bi";
import { AiFillTool } from "react-icons/ai";
import { FaUser } from "react-icons/fa6";
import { BsFillImageFill } from "react-icons/bs";
import {
  IoRocket,
  IoWifiSharp,
  IoDocumentText,
  IoSettingsSharp,
  IoStatsChartSharp,
} from "react-icons/io5";
import { MdVideocam } from "react-icons/md";
import { LuMonitor } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";

const Sidebar = React.memo(({ open }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname);

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location]);

  const handleMenuClick = (path) => {
    setActiveMenu(path);
  };

  const menuItems = [
    { name: "Dashboard", path: "/homepage/dashboard", icon: <FaHome /> },
    {
      name: "My Portfolio",
      path: "/homepage/myportfolio",
      icon: <IoStatsChartSharp />,
    },
    {
      name: "All Transactions",
      path: "/homepage/alltransactions",
      icon: <BiSolidCreditCard />,
    },
    {
      name: "Opportunities",
      path: "/homepage/opportunities",
      icon: <AiFillTool />,
    },
  ];

  const accountPages = [
    { name: "My Profile", path: "/homepage/myprofile", icon: <FaUser /> },
    { name: "KYC", path: "/homepage/kyc", icon: <IoDocumentText /> },
    {
      name: "My Documents",
      path: "/homepage/mydocuments",
      icon: <BsFillImageFill />,
    },
    { name: "Refer & Earn", path: "/homepage/refer&earn", icon: <IoRocket /> },
  ];

  const learnCenter = [
    { name: "Blog", path: "/homepage/blog", icon: <IoWifiSharp /> },
    { name: "Webinar", path: "/homepage/webinar", icon: <MdVideocam /> },
  ];

  const systemPages = [
    {
      name: "Notification Center",
      path: "/homepage/notificationCenter",
      icon: <LuMonitor />,
    },
    { name: "Support", path: "/homepage/support", icon: <BiSupport /> },
    // { name: "Settings", path: "/homepage/settings", icon: <IoSettingsSharp /> },
  ];

  return (
    <div className="hidden md:hidden lg:block">
      <div
        className={`${
          open ? "w-[280px]" : "w-[280px]"
        } h-full bg-white text-black left-0 fixed duration-500 `}
      >
        <div className="w-full px-[24px]">
          <div className="w-full flex flex-row items-center gap-6 mt-10 ml-4 mb-6">
            {/* logo */}
            <div className="flex md:hidden lg:flex flex-row  ">
              <img
                src={sidelogo}
                alt="Riofin"
                className="cursor-pointer  w-10 h-10"
              />
              <h1 className="text-black text-xl mt-2 ml-2 font-bold">
                RIOFIN ASSETS
              </h1>
            </div>
          </div>
          <div className="left-0 right-0 top-0 bottom-0 border-b border-gray-300"></div>
        </div>

        <div className="p-2 px-[24px] mt-6 h-[calc(100vh-160px)] overflow-y-auto">
          <nav>
            <ul>
              {menuItems.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      }`}
                    >
                      {item.icon}
                    </span>
                    <span className="ml-2 font-bold mt-1">{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
            <h2 className="p-2 px-[24px] py-4 font-bold text-[#2272FF]">
              ACCOUNT PAGES
            </h2>
            <ul>
              {accountPages.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      }`}
                    >
                      {item.icon}
                    </span>
                    <span className={`ml-2 font-bold mt-1 relative`}>
                      {index == 1 ? <div className={`text-xs text-red-500 group-hover:text-white font-light absolute left-1/2 -top-2 translate-x-1/2 ${activeMenu == item.path ? "text-white":""}`}>Pending</div> : ""}
                      {item.name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
            <h2 className="p-2 px-[24px] py-4 font-bold text-[#2272FF]">
              LEARN CENTER
            </h2>
            <ul>
              {learnCenter.map((item, index) => (
                <li key={index} className="mb-2">
                  {item.path === "/homepage/webinar" && (
                    <span className="flex items-center p-2 rounded-xl text-black font-medium text-base leading-6 group opacity-50 cursor-not-allowed">
                      <span className="text-xl p-2 rounded-2xl bg-[#2272FF] text-white">
                        <MdVideocam />
                      </span>
                      <span className="ml-2 font-bold mt-1">{item.name}</span>
                    </span>
                  )}
                  {item.path !== "/homepage/webinar" && (
                    <Link
                      to={item.path}
                      className={`flex items-center p-2 rounded-xl text-black font-medium text-base leading-6 group ${
                        activeMenu === item.path
                          ? "bg-[#2272FF] text-white"
                          : "hover:bg-[#2272FF] hover:text-white"
                      }`}
                      onClick={() => handleMenuClick(item.path)}
                    >
                      <span
                        className={`group-hover:bg-white group-hover:text-[#2272FF] text-xl p-2 rounded-2xl ${
                          open ? "ml-2" : ""
                        } ${
                          activeMenu === item.path
                            ? "bg-white text-[#2272FF]"
                            : "bg-[#2272FF] text-white"
                        }`}
                      >
                        {item.icon}
                      </span>
                      <span className="ml-2 font-bold mt-1">{item.name}</span>
                    </Link>
                  )}
                </li>
              ))}
            </ul>

            <h2 className="p-2 px-[24px] py-4 font-bold text-[#2272FF]">
              SYSTEM PAGES
            </h2>
            <ul>
              {systemPages.map((item, index) => (
                <li key={index} className="mb-2">
                  <Link
                    to={item.path}
                    className={`flex items-center p-2 rounded-xl text-black font-medium text-base leading-6 group ${
                      activeMenu === item.path
                        ? "bg-[#2272FF] text-white"
                        : "hover:bg-[#2272FF] hover:text-white"
                    }`}
                    onClick={() => handleMenuClick(item.path)}
                  >
                    <span
                      className={`group-hover:bg-white group-hover:text-[#2272FF] text-xl p-2 rounded-2xl ${
                        open ? "ml-2" : ""
                      } ${
                        activeMenu === item.path
                          ? "bg-white text-[#2272FF]"
                          : "bg-[#2272FF] text-white"
                      }`}
                    >
                      {item.icon}
                    </span>
                    <span className="ml-2 font-bold mt-1">{item.name}</span>
                  </Link>
                </li>
              ))}
              {/* <div className="bg-mygreen mt-5 rounded-xl p-4">
              <img src={ternari} alt="" />
              <h1 className="font-bold">Need help?</h1>
              <p className=" text-xs font-semibold">
                Talk To Our Customer
                <br /> Relationship Manager
              </p>
              <button className="bg-white rounded-xl w-full mt-2 py-2 text-sm font-bold border-b-2 shadow-xl hover:shadow-md border drop-shadow">
                Chat Now
              </button>
            </div> */}
            </ul>
            {/* Other menu sections */}
          </nav>
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
