import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import { Pagination } from "antd";
import { DatePicker } from "antd";

export default function AllTransactions() {
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [searchText, setSearchText] = useState("");

  const tabs = ["All", "Success", "Pending", "Failed"];
  const filters = ["All", "Investment", "Referral Paid", "Refund", "Credited"];

  const columns = [
    {
      name: "Transaction Type",
      selector: (row) => row.transectiontype,
    },
    {
      name: "Project Name",
      selector: (row) => row.projectname,
      style: {
        fontWeight: "medium",
        color: "#919191",
      },
    },
    {
      name: "Time Date",
      selector: (row) => row.timedate,
      style: {
        fontWeight: "medium",
        color: "#919191",
      },
    },
    {
      name: "Payment Method",
      selector: (row) => row.projectname2,
      style: {
        fontWeight: "medium",
        color: "#919191",
      },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "Status",
      selector: (row) => (
        <h1
          className={`${
            row.status === "Success"
              ? "text-mygreen"
              : row.status === "Failed"
              ? "text-red-500"
              : row.status === "Pending"
              ? "text-blue-500"
              : ""
          }`}
        >
          {row.status}
        </h1>
      ),

      style: {
        fontWeight: "bold",
      },
    },
  ];

  // cell: (row) => (
  //   <>
  //     <div className="flex flex-row items-center gap-3">
  //       <FaLightbulb className="w-8 h-6 text-blue-500 cursor-pointer" />
  //       <FaLightbulb className="w-8 h-6 text-red-500 cursor-pointer" />

  //       <FaLightbulb className="w-8 h-5 text-blue-500 cursor-pointer" />
  //     </div>
  //   </>
  // ),

  const data = [
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Offline",
      amount: "₹5,00,000",
      status: "Success",
    },
    {
      transectiontype: "Refund",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Gpay",
      amount: "₹3,00,000",
      status: "Success",
    },
    {
      transectiontype: "Investment",
      projectname: "Shanas Infra",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "Credit card",
      amount: "₹3,00,000",
      status: "Pending",
    },
    {
      transectiontype: "Credited",
      projectname: "Rio Global V LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "offline",
      amount: "₹3,00,000",
      status: "Success",
    },
    {
      transectiontype: "Referral Paid",
      projectname: "Rio Global IV LLP",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "offline",
      amount: "₹3,00,000",
      status: "Failed",
    },
    {
      transectiontype: "Credited",
      projectname: "shanas infra",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "offline",
      amount: "₹3,00,000",
      status: "Success",
    },
    {
      transectiontype: "Credited",
      projectname: "shanas infra",
      timedate: "7pm, 3th Nov 2023",
      projectname2: "offline",
      amount: "₹3,00,000",
      status: "Success",
    },
    {
      transectiontype: "Credited",
      projectname: "shans infra",
      timedate: "7pm, 4th Nov 2023",
      projectname2: "offline",
      amount: "₹3,00,000",
      status: "Success",
    },
  ];
  const [filtervalues, Setfilter] = useState(data);

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        paddingLeft: "1px",
        paddingRight: "2px",
        fontWeight: "600",
        textAlign: "center",
        left: "8px",
        padding: "2px",
      },
    },

    rows: {
      style: {
        backgroundColor: "white",
        borderTop: "1px solid #ddd",
        borderBottom: "1px solid #ddd",
        paddingTop: "8px",
        paddingBottom: "8px",
        marginBottom: "14px",
        borderRadius: "8px",
      },
      highlightOnHoverStyle: {
        backgroundColor: "transparent",
        borderBottomColor: "transparent",
        // outline: "1px solid transparent",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.status === "Success",
      style: {
        backgroundColor: "white",
        outline: "2px solid #00ff00",
        borderRight: "2px solid #00ff00",
        borderLeft: "2px solid #00ff00",
        boxShadow: "0 4px 8px rgba(0, 255,0, 0.2 )",
        cells: {
          // border: "2px solid #00ff00", // Full border color for cells
          color: "mygreen",
        },
      },
    },

    {
      when: (row) => row.status === "Pending",
      style: {
        backgroundColor: "rgba(173, 216, 230, 0.3)",
        outline: "2px solid #2272FF",
        borderRight: "2px solid #2272FF",
        borderLeft: "2px solid #2272FF",
        boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)",
      },
    },
    {
      when: (row) => row.status === "Failed",
      style: {
        cells: {
          color: "red",
        },
        backgroundColor: "rgba(255, 99, 71, 0.3)",
        outline: "2px solid #EB6383",
        borderRight: "2px solid #EB6383",
        borderLeft: "2px solid #EB6383",
        boxShadow: "0 4px 8px rgba(255, 0, 0, 0.2)",
      },
    },
  ];

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setSelectedFilter("All"); // Reset filter when tab changes
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredData = filtervalues.filter((row) => {
    const matchesSearch = row.transectiontype
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const matchesTab =
      selectedTab === "All" ||
      row.status.toLowerCase() === selectedTab.toLowerCase();
    const matchesFilter =
      selectedFilter === "All" ||
      row.transectiontype.toLowerCase() === selectedFilter.toLowerCase();

    return matchesSearch && matchesTab && matchesFilter;
  });
  function filtered(value) {
    const date = value || "";
    const monthName = date
      ?.toLocaleString("en-US", { month: "long" })
      .split(" ");

    const dateandMonth = `0${parseInt(monthName[1]) + 1}th ${monthName[2]} ${
      monthName[3]
    }`;

    const filtered = data.filter((item, index) => {
      const spildata = item.timedate.split(" ");

      const times = `0${spildata[1]} ${spildata[2]} ${spildata[3]}`;
      console.log(times, dateandMonth);
      return times.includes(dateandMonth);
    });
    console.log(filtered);
    Setfilter(filtered);
  }

  const pagination = {
    pageSize: 8, // Number of rows per page
  };
  return (
    <div className="bg-gray-100">
      <div className="bg-white m-4 rounded-lg p-4">
        <div className="relative md:justify-end w-full mt-4 flex sm:flex-row flex-col gap-5 text-center">
          <DatePicker
            className="rounded-2xl px-3 py-2 "
            onChange={(value) => {
              value != null ? filtered(value) : Setfilter(data);
            }}
          ></DatePicker>
          <select
            value={selectedFilter}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="px-3 py-2  border rounded-2xl "
          >
            {filters.map((filter) => (
              <option key={filter} value={filter}>
                {filter}
              </option>
            ))}
          </select>
        </div>
        <div>
          <h1 className="text-gray-500 ml-4  text-xl">All Transactions</h1>
        </div>
        <div>
          <div className="flex md:space-x-3 space-x-auto mt-4">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`${
                  selectedTab === tab
                    ? "text-black underline-hover-mygreen"
                    : "hover:underline font-bold"
                } px-4 py-2 rounded`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="md:px-5">
            <DataTable
              pagination={pagination}
              columns={columns}
              data={filteredData}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
          <hr className="border-2 bg-gray-100 rounded h-2 shadow-md"></hr>
        </div>
        <div className="text-center py-5"></div>
      </div>
    </div>
  );
}
