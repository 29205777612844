import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaCalendar } from "react-icons/fa";
import { BiCaretDown } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";
import { DatePicker } from "antd";
import { jsPDF } from "jspdf";

const RequestReport = () => {
  const [selectedFilter, setSelectedFilter] = useState("");

  const data = [
    {
      id: 1,
      timedate: "7pm, 4th Nov 2023",
      transactionid: "458565545",
      amount: "₹1,00,000",
      status: "Success",
    },
    {
      id: 2,
      timedate: "7pm, 4th Nov 2023",
      transactionid: "458565545",
      amount: "₹3,00,000",
      status: "Success",
    },
    {
      id: 3,
      timedate: "7pm, 4th Nov 2023",
      transactionid: "458565545",
      amount: "₹3,00,000",
      status: "Pending",
    },
    {
      id: 4,
      timedate: "7pm, 4th Nov 2023",
      transactionid: "458565545",
      amount: "₹3,00,000",
      status: "Success",
    },
    {
      id: 5,
      timedate: "7pm, 4th Nov 2023",
      transactionid: "458565545",
      amount: "₹3,00,000",
      status: "Failed",
    },
  ];

  const filteredData = selectedFilter
    ? data.filter((row) => row.status === selectedFilter)
    : data;

  const receiptColumn = {
    name: "Receipt",
    cell: (row) => (
      <button
        onClick={() =>
          handleDownloadReceipt(
            row.transactionid,
            row.timedate,
            row.amount,
            row.status
          )
        }
        className="text-blue-500 hover:underline focus:outline-none ml-3 bg-white"
        style={{
          border: "2px solid #2196F3",
          borderRadius: "8px",
          padding: "8px",
          display: "block",
        }}
      >
        <FaDownload />
      </button>
    ),
  };

  const columns = [
    {
      name: "Time & Date",
      selector: (row) => row.timedate,
      style: { fontWeight: "medium", color: "#919191" },
    },
    {
      name: "Transaction id",
      selector: (row) => row.transactionid,
      style: { fontWeight: "medium", color: "#919191" },
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      style: { fontWeight: "bold" },
    },
    {
      name: "Status",
      selector: (row) => (
        <h1
          className={`${
            row.status === "Success"
              ? "text-mygreen"
              : row.status === "Failed"
              ? "text-red-500"
              : row.status === "Pending"
              ? "text-blue-500"
              : ""
          }`}
        >
          {row.status}
        </h1>
      ),
      style: { fontWeight: "bold" },
    },
    receiptColumn,
  ];

  const customStyles = {
    headRow: { style: { border: "none", outerWidth: "600px" } },
    headCells: {
      style: { fontSize: "15px", fontWeight: "700", textAlign: "center" },
    },
    rows: {
      style: {
        backgroundColor: "white",
        paddingTop: "8px",
        paddingBottom: "8px",
        marginBottom: "14px",
        borderRadius: "8px",
      },
    },
    pagination: { style: { border: "none" } },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.status === "Success",
      style: {
        backgroundColor: "white",
        outline: "2px solid #00ff00",
        borderRight: "2px solid #00ff00",
        borderLeft: "2px solid #00ff00",
        boxShadow: "0 4px 8px rgba(0, 255,0, 0.2 )",
        cells: { color: "mygreen" },
      },
    },
    {
      when: (row) => row.status === "Pending",
      style: {
        backgroundColor: "rgba(173, 216, 230, 0.3)",
        outline: "2px solid #2272FF",
        borderRight: "2px solid #2272FF",
        borderLeft: "2px solid #2272FF",
        boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)",
      },
    },
    {
      when: (row) => row.status === "Failed",
      style: {
        cells: { color: "red" },
        backgroundColor: "rgba(255, 99, 71, 0.3)",
        outline: "2px solid #EB6383",
        borderRight: "2px solid #EB6383",
        borderLeft: "2px solid #EB6383",
        boxShadow: "0 4px 8px rgba(255, 0, 0, 0.2)",
      },
    },
  ];

  const [showCalendar, setShowCalendar] = useState(false);

  const handleDivClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDownloadReceipt = (transactionId, timeDate, amount, status) => {
    const doc = new jsPDF();
    const receiptContent = `Receipt for transaction ID ${transactionId}\nDate: ${timeDate}\nAmount: ${amount}\nStatus: ${status}`;

    doc.text(receiptContent, 10, 10);
    doc.save(`receipt_${transactionId}.pdf`);
  };

  return (
    <section className="bg-gray-100 rounded-xl mt-5">
      <div className="sm:p-4">
        <div className="md:p-10 p-5 bg-white rounded-lg">
          <div className="relative md:text-right mt-2 text-center"></div>
          <h2 className="text-xl font-semibold pb-3">Request Reports</h2>
          <div className="flex md:flex-row flex-col md:space-x-5 md:pb-8">
            <p className="pt-1 text-gray-400 pb-3 md:pb-0">
              (All reports related to your referral amount request)
            </p>
            <div
              className="text-blue-500 flex border h-fit border-blue-500 w-max bg-white shadow-lg p-2 rounded-md cursor-pointer"
              onClick={handleDivClick}
            >
              <FaCalendar className="mr-1" />
              <BiCaretDown />
            </div>
            {showCalendar && <DatePicker />}
            <div className="relative">
              <div className="flex">
                <select
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  className="px-3 py-1 md:pl-12 pl-5 md:ml-1 border border-blue-500 rounded-md pr-10 sm:w-auto md:w-auto"
                >
                  <option value="">All</option>
                  <option value="Success">Success</option>
                  <option value="Pending">Pending</option>
                  <option value="Failed">Failed</option>
                </select>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <DataTable
              columns={columns}
              data={filteredData}
              customStyles={customStyles}
              pointerOnHover
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
          <div className="text-center pt-5">
            <button className="bg-blue-500 p-2 text-white text-center px-10 mx-auto">
              LOAD MORE
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequestReport;
