import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import { FaCalendar } from "react-icons/fa";
import { BiCaretDown } from "react-icons/bi";
import { DatePicker } from "antd";

const data = [
  {
    sno: 1,
    username: "johnny",
    projectname: "Riofin",
    username2: "02-03-2024",
    amount: "₹50,000",
    Invested: "₹1,00,000",
  },
  {
    sno: 2,
    username: "johnny",
    projectname: "Riofin",
    username2: "",
    amount: "₹2,00,000",
    Invested: "₹3,00,000",
  },
  {
    sno: 3,
    username: "johnny",
    projectname: "Riofin",
    username2: "",
    amount: "₹2,00,000",
    Invested: "₹3,00,000",
  },
  {
    sno: 4,
    username: "johnny",
    projectname: "Riofin",
    username2: "",
    amount: "₹2,00,000",
    Invested: "₹3,00,000",
  },
  {
    sno: 5,
    username: "johnny",
    projectname: "Riofin",
    username2: "",
    amount: "₹1,00,000",
    Invested: "₹3,00,000",
  },
  {
    sno: 6,
    username: "johnny",
    projectname: "Riofin",
    username2: "",
    amount: "₹2,50,000",
    Invested: "₹3,00,000",
  },
  {
    sno: 7,
    username: "johnny",
    projectname: "Riofin",
    username2: "",
    amount: "₹1,00,000",
    Invested: "₹3,00,000",
  },
];

export default function ReferEarn() {
  const [activeButton, setActiveButton] = useState("Refferral List");
  const [searchText, setSearchText] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [filterValues, setFilterValues] = useState(data);

  const handleClick = (buttonText) => {
    setActiveButton(buttonText);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filteredData = filterValues.filter(
    (row) =>
      row.username.toLowerCase().includes(searchText.toLowerCase()) ||
      row.projectname.toLowerCase().includes(searchText.toLowerCase()) ||
      row.username2.toLowerCase().includes(searchText.toLowerCase()) ||
      row.amount.toLowerCase().includes(searchText.toLowerCase()) ||
      row.Invested.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDateFilter = (value) => {
    if (value) {
      const dateString = value.format("DD-MM-YYYY");
      const filtered = data.filter((item) => item.username2.includes(dateString));
      setFilterValues(filtered);
    } else {
      setFilterValues(data);
    }
  };

  const handleDivClick = () => {
    setShowCalendar(!showCalendar);
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.sno % 7 === 0,
      style: {
        backgroundColor: "rgba(173, 216, 230, 0.3)",
        outline: "2px solid #2272FF",
        borderRight: "2px solid #2272FF",
        borderLeft: "2px solid #2272FF",
        boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)",
        borderRadius: "8px",
      },
    },
    {
      when: (row) => row.sno % 2 === 0,
      style: {
        borderTop: "2px solid #4b8cfd",
        borderBottom: "2px solid #4b8cfd",
        borderLeft: "2px solid #4b8cfd",
        borderRight: "2px solid #4b8cfd",
        backgroundColor: "white",
        borderRadius: "8px",
        marginBottom: "16px",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      },
    },
    {
      when: (row) => row.sno % 1 === 0,
      style: {
        outline: "2px solid #2272FF",
        borderRight: "2px solid #2272FF",
        borderLeft: "2px solid #2272FF",
        marginBottom: "16px",
        boxShadow: "0 4px 8px rgba(0, 0, 255, 0.2)",
        borderRadius: "8px",
      },
    },
  ];

  const combinedColumns = [
    {
      name: "S.NO",
      selector: (row) => row.sno,
      style: {
        fontWeight: "bold",
      },
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      style: {
        fontWeight: "medium",
        color: "#919191",
      },
    },
    {
      name: "Project Name",
      selector: (row) => row.projectname,
      style: {
        fontWeight: "bold",
        color: "#868686",
      },
    },
    {
      name: "Invested Date",
      selector: (row) => row.username2,
      style: {
        fontWeight: "bold",
        color: "#000000",
      },
    },
    {
      name: "Invested Amount",
      selector: (row) => row.Invested,
      style: {
        fontWeight: "bold",
        color: "#2c79ff",
      },
    },
    {
      name: "Earned Amount",
      selector: (row) => row.amount,
      style: {
        fontWeight: "bold",
        color: "#00a200",
      },
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "none",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textAlign: "center",
        padding: "2px",
        color: "black",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
  };

  return (
    <div className="bg-gray-100 md:ml-5 md:mr-5 rounded-lg">
      <div className="bg-white md:m-4 rounded-lg md:p-4 ">
        <div className="relative md:text-right mt-2 text-center">
          <div className="relative ">
            <AiOutlineSearch className="absolute md:right-60 hidden md:block top-3 text-xl text-gray-900" />
            <input
              type="search"
              placeholder="Type here..."
              className="px-3 py-2 md:pl-12 pl-5 ml-1 border rounded-2xl pr-10 sm:w-auto md:w-auto"
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className="m-10">
          <h1 className="text-black font-semibold mb-3 text-xl">
            Your Referrals
          </h1>
          <div className="flex sm:flex-row flex-col items-left gap-4">
            <button
              className={`px-10 p-2 border-2 border-solid rounded-md ${
                activeButton === "Refferral List"
                  ? "bg-blue-500 text-white shadow-md"
                  : "border-blue-500"
              }`}
              onClick={() => handleClick("Refferral List")}
            >
              Referrals List
            </button>
            <button
              className={`p-2 px-4 border-2 border-solid rounded-md ${
                activeButton === "Refferral not Yet Invested"
                  ? "border-blue-500 bg-blue-500 text-white shadow"
                  : "border-blue-500"
              }`}
              onClick={() => handleClick("Refferral not Yet Invested")}
            >
              Referral Invested
            </button>
            <button
              className="text-blue-500 flex border h-fit border-blue-500 w-max bg-white shadow-lg p-4 rounded-md cursor-pointer"
              onClick={handleDivClick}
            >
              <FaCalendar className="mr-1" />
              <BiCaretDown />
            </button>
            {showCalendar && (
              <DatePicker onChange={(value) => handleDateFilter(value)} />
            )}
          </div>
          <DataTable
            columns={combinedColumns}
            data={filteredData}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
          />
          <hr className="border-2 bg-gray-100 rounded h-2 shadow-md" />
        </div>
        <div className="text-center">
          <button className="bg-blue-500 p-2 text-white text-center px-10 mx-auto rounded-md">
            LOAD MORE
          </button>
        </div>
      </div>
    </div>
  );
}
