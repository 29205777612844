import React, { useEffect, useState, useRef } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Img1 from "../../../assets/prof-1.png";
import { FaUser } from "react-icons/fa6";
import { ImClock, ImStatsBars } from "react-icons/im";
import { Modal, Form, Input, Button } from "antd";
import Img7 from "../../../assets/profile (2).png";
import { CiEdit } from "react-icons/ci";
import axios from "axios";
import yourThumbnailImage from "../../../assets/video.png";
import { ToastContainer, toast } from "react-toastify";
import { FaPlayCircle } from "react-icons/fa";

const Profile = () => {
  const rioApiUrl = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    nominee: "",
    nomineeRelationship: "",
    nomineeMobile: "",
    country: "",
    state: "",
    city: "",
    image: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetch(`${rioApiUrl}/user/view-kyc`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user) {
          const userDetail = data.user_detail || {};
          setFormData({
            name: data.user.name || "",
            email: data.user.email || "",
            mobile: data.user.mobile || "",
            nominee: userDetail.nominee_name || "",
            nomineeRelationship: userDetail.nominee_relation || "",
            nomineeMobile: userDetail.nominee_mobile || "",
            country: userDetail.country || "",
            state: userDetail.state || "",
            city: userDetail.city || "",
            image: userDetail.image ? userDetail.image : Img1,
          });
          localStorage.setItem("user_data", JSON.stringify(data));
        } else {
          console.error("Invalid user data structure:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    // Check if formData is initialized before accessing its properties
    if (!formData) {
      console.error("formData is not initialized");
      return;
    }

    // Create a FormData object
    const formDataToSend = new FormData();

    // Append form data fields
    formDataToSend.append("name", formData.name);
    formDataToSend.append("mobile", formData.mobile);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("kyc_verified", formData.kyc_verified);
    formDataToSend.append("country", formData.country);
    formDataToSend.append("state", formData.state);
    formDataToSend.append("city", formData.city);
    formDataToSend.append("address", formData.address);
    formDataToSend.append("pincode", formData.pincode);
    formDataToSend.append("nominee_name", formData.nominee);
    formDataToSend.append("nominee_relation", formData.nomineeRelationship);
    formDataToSend.append("nominee_mobile", formData.nomineeMobile);

    // Check if an image is selected
    if (selectedImage) {
      formDataToSend.append("image", selectedImage);
    }

    try {
      const response = await axios.post(
        `${rioApiUrl}/user/profile-update`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Profile updated successfully!", response.data);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log("Form submitted:", formData);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleModalSubmit = async (values) => {
    try {
      // Use the values object to send form data to the backend
      const response = await axios.post(
        `${rioApiUrl}/user/enquiry-send `,
        values
      );

      // Handle the response as needed
      console.log("Form submitted successfully!", response.data);

      // Close the modal or perform any other actions
      closeModal();
    } catch (error) {
      // Handle errors if the request fails
      console.error("Error submitting form:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      const response = await axios.post(`${rioApiUrl}/user/logout`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(response);

      localStorage.removeItem("user_type");
      localStorage.removeItem("id");
      localStorage.removeItem("token");

      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(referralData.referral_code)
      .then(() => {
        setCopySuccess(true);
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        toast.error("Failed to copy to clipboard");
      });
  };

  const handlePlayClick = () => {
    setVideoPlaying(true);
  };
  const formatNumberWithIndianSystem = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const [referralData, setReferralData] = useState({
    earned_amount: 0,
    settled_amount: 0,
    pending_amount: 0,
    my_referals: [],
    referral_code: "",
  });

  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  return (
    <div className=" bg-gray-100  w-full">
      <div className="m-8 bg-[#F6F9FF] border border-blue-500 shadow-lg rounded-lg lg:flex sm:grid-cols-1">
        <div className="lg:w-2/1 p-4">
          <div className="bg-[#F1FFF1] h-full p-4 rounded-lg shadow-md">
            <div className="flex justify-center">
              <div className="relative">
                {selectedImage ? (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected Image"
                    className="object-cover relative h-48 w-48 rounded-full border ring-2 border-mygreen"
                  />
                ) : (
                  <img
                    src={Img1}
                    alt="Your Alt Text"
                    className="object-cover relative h-48 w-48 rounded-full"
                  />
                )}
                <div
                  className="absolute  top-32 h-10 w-10 text-mygreen rounded-full bg-gray-100 -right-[10px]  mr-4 mt-4 flex items-center justify-center   cursor-pointer"
                  onClick={handleFileUpload}
                >
                  <CiEdit size={32} className="" />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <h2 className="text-xl text-center font-bold">{formData.name}</h2>
            <p className="text-gray-500 pb-4  text-center">{formData.email}</p>
            <hr className="pb-4"></hr>
            <div className="relative">
              <Link to="/homepage/kyc">
                {" "}
                <p className="py-5 flex justify-start pl-3 items-center gap-3  hover:bg-white hover:text-black  ">
                  <ImStatsBars className="text-blue-500 " />
                  KYC
                </p>
              </Link>
              <div className="absolute top-3 right-1/2 translate-x-1/2">
                <p className="text-xs text-red-500">Pending</p>
              </div>
            </div>
            <div className="">
              <p
                className="md:py-5 flex justify-start pl-3 items-center gap-3   hover:text-black  "
                onClick={openModal}
              >
                {/* <MdOutlineMessage className='text-blue-500 ' /> */}
              </p>
            </div>
            <div className="w-full">
              <p className="py-5 flex justify-start pl-3 items-center gap-3   hover:text-black  ">
                {/* <FiFileText className='text-blue-500 ' />Terms & Condition */}
              </p>
            </div>
            <div className="w-full">
              <button
                className="py-5 flex justify-start pl-3 items-center gap-3   hover:text-black  "
                onClick={handleSignOut}
              >
                {/* <PiSignOutBold className='text-blue-500 ' />Signout */}
              </button>
            </div>
          </div>
        </div>

        {/* Right column - Information and Form */}
        <div className="lg:w-2/2 lg:w-[700px] p-4">
          <div className=" p-4 rounded-lg shadow-md">
            {/* Add content for information */}
            {/* Submit button */}
            <div className="md:flex">
              <button
                type="submit"
                className=" flex justify-start  pl-3 items-center gap-3 rounded-lg  bg-[#00FF00] text-black font-bold text-sm px-4 py-2 mt-4"
              >
                <FaUser className="text-black text-lg " />
                My Portfolio
              </button>
            </div>
            {/* Add more information as needed */}

            {/* Form */}
            <form onSubmit={handleSubmit}>
              {/* Name Field */}
              <div className="lg:flex sm:grid-cols-2">
                {/* First column - Line2 */}
                <div className="flex-1 mt-6  pr-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Name
                    <span className="text-red-600 text-xl font-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Enter Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Second column - Phone Field */}
                <div className="flex-1 mt-6  lg:pl-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Email
                    <span className="text-red-600 text-xl font-bold ">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-1 p-2 w-full  border rounded-md"
                    value={formData.email}
                    placeholder="Email ID"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="lg:flex sm:grid-cols-2">
                {/* First column - Line2 */}
                <div className="flex-1 mt-6  lg:pr-2">
                  <label
                    htmlFor="name"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Mobile
                    <span className="text-red-600 text-xl font-bold ">*</span>
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Enter your mobile number"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Second column - Phone Field */}
                <div className="flex-1 mt-8  lg:pl-2">
                  <label
                    htmlFor="nominee"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Nominee(Optional)
                  </label>
                  <input
                    type="text"
                    id="nominee"
                    name="nominee"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Nominee Name"
                    value={formData.nominee}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="lg:flex sm:grid-cols-2">
                {/* First column - Line2 */}
                <div className="flex-1 mt-6  lg:pr-2">
                  <label
                    htmlFor="nomineeRelationship"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Nominee Relationship
                  </label>
                  <input
                    type="text"
                    id="nomineeRelationship"
                    name="nomineeRelationship"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Nominee Name"
                    value={formData.nomineeRelationship}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Second column - Phone Field */}
                <div className="flex-1 mt-6  lg:pl-2">
                  <label
                    htmlFor="nomineeMobile"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Nominee Mobile
                  </label>
                  <input
                    type="tel"
                    id="nomineeMobile"
                    name="nomineeMobile"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Number"
                    value={formData.nomineeMobile}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="lg:flex sm:grid-cols-2">
                {/* First column - Line2 */}
                <div className="flex-1 mt-6  lg:pr-2">
                  <label
                    htmlFor="country"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    Country
                    <span className="text-red-600 text-xl font-bold">*</span>
                  </label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Enter your country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Second column - Phone Field */}
                <div className="flex-1 mt-6  lg:pl-2">
                  <label
                    htmlFor="state"
                    className="block text-sm font-bold text-[#1d5ca2]"
                  >
                    State
                    <span className="text-red-600 text-xl font-bold">*</span>
                  </label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    className="mt-1 p-2 w-full border rounded-md"
                    placeholder="Enter your state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="flex-1 mt-6 lg:w-1/2 lg:pl-2">
                <label
                  htmlFor="state"
                  className="block text-sm font-bold text-[#1d5ca2]"
                >
                  City<span className="text-red-600 text-xl font-bold">*</span>
                </label>
                <input
                  type="text"
                  id="City"
                  name="City"
                  className="mt-1 p-2 w-full  border rounded-md lg:w-2/2" // Adjusted class
                  placeholder="Enter your City"
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
              </div>

              {/* Submit button */}
              <div className="md:flex gap-4">
                <div className="py-4">
                  <button
                    type="submit"
                    className="bg-blue-500 md:text-lg text-white md:px-12 px-14 py-3 font-semibold rounded-lg md:inline-block block mt-4 md:mt-0"
                    onClick={handleUpdate}
                  >
                    Confirm
                  </button>
                </div>
                <div className="py-4">
                  <Link to="/homepage/support">
                    {" "}
                    <button
                      type="submit"
                      className="bg-blue-500 md:text-lg text-white px-10 md:px-12 py-3 font-semibold rounded-lg md:inline-block block"
                    >
                      Edit
                    </button>{" "}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Second Section */}
      <div class="flex flex-col mb-10 ml-5 mr-3  lg:gap-4">
        {/* <!-- First Card --> */}
        {/* <div class="lg:w-4/6 bg-white rounded-xl p-4 mb-4 lg:mb-0 flex flex-col lg:flex-row md:gap-20">
          <div>
            <p class="text-sm text-gray-500 font-semibold">
              Built For Investors, Built By Investors
              <br />
            </p>
            <p class="font-bold">Investor’s Hub</p>
            <p class="text-sm text-gray-500 font-normal">
              News and Trends in the investment world will be displayed here...
            </p>
            <div class="flex items-center justify-between mt-4 lg:mt-20">
              <a
                href="/homepage/blog"
                class="text-black font-bold text-xl cursor-pointer hover:text-blue-500"
              >
                Read More <span class="ml-2 font-bold">&rarr;</span>
              </a>
            </div>
          </div>
          <div>
            <div class="bg-mygreen p-2 px-8 pl-10 rounded-lg grid place-items-center">
              <h1 class="text-black font-bold text-center">
                New Project Launch
              </h1>
              <p class="text-center text-sm">Rio Global Warehouse Iv LLP</p>
              <ImClock class="text-[77px] text-white pt-4" />
              <p class="text-black text-center lg:mt-5">Launching Soon</p>
            </div>
          </div>
        </div> */}
        <p className="text-2xl font-bold">Get Help</p>
        {/* <!-- Second Card --> */}
        <div class="lg:w-1/2  bg-white rounded-lg relative lg:mb-0 mb-5 p-2 md:p-4">
          {/* <!-- Your content for the second card goes here --> */}
          <div className="relative">
            <h1 className="absolute  left-2 text-white md:text-xl text-sm font-semibold">
              {" "}
              Learn How To Access Your Dashboard
              <br></br>
              <p className="text-sm">
                Discover accessing your dashboard for instant insights on
                investments, profits, and portfolio at RioFin Assets
                effortlessly.
              </p>
            </h1>
            {/* <p>Discover accessing your dashboard for instant insights on investments, profits, and portfolio at RioFin Assets effortlessly.</p> */}

            <img
              src={yourThumbnailImage}
              alt="Video Thumbnail"
              className="w-full h-full object-cover cursor-pointer  border-mygreen border-r-8 border-b-8 rounded-[26px]"
              onClick={handlePlayClick}
            />
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-[50%] left-[50%]">
              <FaPlayCircle
                className="w-14 h-14 text-white cursor-pointer"
                onClick={handlePlayClick}
              />
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default Profile;
