import React, { useEffect } from "react";
import { Form, Input, Button } from "antd"; // Assuming Ant Design
import axios from "axios"; // Importing axios for making HTTP requests

const Support = () => {
  const rioApiUrl = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm(); // Importing Form from 'antd' and initializing it

  const handleSubmit = async (values) => {
    try {
      // Use the values object to send form data to the backend
      const response = await axios.post(
        `${rioApiUrl}/user/enquiry-send`,
        values
      );

      // Handle the response as needed
      console.log("Form submitted successfully!", response.data);
    } catch (error) {
      // Handle errors if the request fails
      console.error("Error submitting form:", error);
    }
  };
  useEffect(()=>{
    window.scrollTo({
      top:0,
      behavior:"smooth",
    })
  },[])

  return (
    <div className=" p-5">
      <div className="md:ml-auto md:mr-auto bg-[#f6f9ff] border     border-blue-500 mt-5 mb-5 lg:mt-auto rounded-xl h-auto shadow-xl w-full md:w-3/4 lg:w-1/2">
        <h1 className="text-black text-xl text-center mt-7 font-bold">
          Connect With Us
        </h1>
        <p className="text-black text-center ">
          You can expect a reply from our team within 24 hours.
        </p>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          style={{ padding: "20px", borderRadius: "10px" }}
          requiredMark="required"
        >
          <div className="pl-0 pr-0 md:pl-0 md:pr-0">
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
              labelCol={{ style: { color: "#1d5ca2" } }} // Set label text color
            >
              <Input placeholder="Enter Your Name" className="text-gray-800" />{" "}
              {/* Set input placeholder text color */}
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Enter Your Email" />
            </Form.Item>

            <Form.Item
              label="Mobile Number"
              rules={[
                { required: true, message: "Please enter your mobile number" },
              ]}
              name="mobile"
            >
              <Input placeholder="Enter Your Mobile Number" />
            </Form.Item>

            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Please enter the subject" }]}
            >
              <Input placeholder="Enter The Subject" />
            </Form.Item>

            <Form.Item
              label="Message"
              name="message"
              rules={[{ required: true, message: "Please enter your message" }]}
            >
              <Input.TextArea placeholder="Enter Your Message" />
            </Form.Item>

            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ textAlign: "center" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#00ff00",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                Send Message
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Support;
