import React, { useState } from 'react'
import Snapshot from './Snapshot';
import Investments from './Investments';




export default function Myortfolio() {

  


  const [showSnapshot, setShowSnapshot] = useState(true);
  const [showInvestments, setShowInvestments] = useState(false);


  const handleSnapshotClick = () => {
    setShowSnapshot(true);
    setShowInvestments(false);
  };

  const handleInvestmentsClick = () => {
    setShowInvestments(true);
    setShowSnapshot(false);
  };

  return (
    <div className=" bg-gray-100  w-full">

      {/* Main Content */}
      <div>


        {/* Second Section */}
       
        <div className="flex flex-row md:flex-row p-10 gap-1">
         
          <button
            className={`flex items-center justify-center sm:text-2xl text-base font-bold text-black ${
              showSnapshot ? 'text-blue-500 border-b-2 border-blue-500' : ''
            }`}
            onClick={handleSnapshotClick}
          >
            Snapshot
          </button>
        
          <p className="text-2xl font-bold text-black">|</p>
          
          <button
            className={`flex items-center justify-center sm:text-2xl text-base font-bold text-black ${
              showInvestments ? 'text-blue-500 border-b-2 border-blue-500' : ''
            }`}
            onClick={handleInvestmentsClick}
          >
            My Investments
          </button>
        </div>
        {showSnapshot && <Snapshot />}
        {showInvestments && 
          <div className='px-10'>
            <Investments />
          </div>}


      </div>
    </div>

  )
}
