import React, { useState } from "react";
import { BiSolidBell } from "react-icons/bi";
import second from "../../../assets/Dashboard.png";
import { TbBellRinging2Filled } from "react-icons/tb";
import { HiLightBulb } from "react-icons/hi";
import { FaWallet, FaCheckCircle, FaPlayCircle } from "react-icons/fa";
import { IoMdShare, IoIosBulb } from "react-icons/io";
import DataTable from "react-data-table-component";
import { SiJirasoftware } from "react-icons/si";
import Active from "./Active";
import { ImClock } from "react-icons/im";
import { AiOutlineSearch } from "react-icons/ai";
import yourThumbnailImage from "../../../assets/video.png";
import { ToastContainer, toast } from "react-toastify";

const Dashboard = () => {
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "semibold",
        fontSize: "11px",
        color: "#a5a5a5",
      },
    },
  };

  const [isVideoPlaying, setVideoPlaying] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(referralData.referral_code)
      .then(() => {
        setCopySuccess(true);
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        toast.error("Failed to copy to clipboard");
      });
  };

  const handlePlayClick = () => {
    setVideoPlaying(true);
  };
  const formatNumberWithIndianSystem = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const [referralData, setReferralData] = useState({
    earned_amount: 0,
    settled_amount: 0,
    pending_amount: 0,
    my_referals: [],
    referral_code: "",
  });

  const [steps, setSteps] = useState([
    { id: 1, text: "Checked Notification", date: "22 NOV 7:20 PM" },
    {
      id: 2,
      text: "Requested Manual Payment #4219423",
      date: "21 DEC 11:21 PM",
    },
    { id: 3, text: "Viewed RIO IV Deal Page", date: "21 DEC 9:28 PM" },
    { id: 4, text: "Mobile Number Updated", date: "20 DEC 3:52 PM" },
    { id: 5, text: "Checked Notification", date: "19 DEC 11:35 PM" },
    { id: 6, text: "KYC Verified", date: "18 DEC 4: 41 PM" },
  ]);
  const datas = [
    {
      id: "1",
      project_listed: "Warehouse IV LLP",
      ROI: "18%",
      invested: "₹1,00,000",
      tensure: "60",
    },
    {
      id: "2",
      project_listed: "Shanas Infra",
      ROI: "60%",
      invested: "₹50,000",
      tensure: "10",
    },
    {
      id: "1",
      project_listed: "Warehouse V LLP",
      ROI: "14%",
      invested: "₹25,00,000",
      tensure: "100",
    },
    {
      id: "2",
      project_listed: "Warehouse VI LLP",
      ROI: "24%",
      invested: "₹7,00,000",
      tensure: "100",
    },
    {
      id: "1",
      project_listed: "Roadways Infra",
      ROI: "30%",
      invested: "₹9,00,000",
      tensure: "25",
    },
    {
      id: "2",
      project_listed: "Warehouse VII LLP",
      ROI: "12%",
      invested: "₹75,000",
      tensure: "40",
    },
  ];
  // Add more data as needed

  const columns = [
    {
      name: "Project Listed",

      selector: (row) => row.project_listed,
      cell: (row) => (
        <>
          {row.id === "1" && (
            <>
              <IoIosBulb className="size-8 text-yellow-400" />
              <span style={{ color: "black", fontWeight: "bold" }}>
                {row.project_listed}
              </span>
            </>
          )}
          {row.id === "2" && (
            <>
              <SiJirasoftware className="size-6 text-[#2272FF]" />
              <span
                style={{
                  color: "black",
                  fontWeight: "bold",
                  paddingLeft: "6px",
                }}
              >
                {row.project_listed}
              </span>
            </>
          )}
        </>
      ),
    },
    {
      name: "ROI%PA",
      selector: (row) => row.ROI,
      cell: (row) => (
        <span style={{ color: "black", fontWeight: "bold" }}>{row.ROI}</span>
      ),
    },

    {
      name: "INVESTED",
      selector: (row) => row.invested,
      cell: (row) => (
        <span style={{ color: "black", fontWeight: "bold" }}>
          {row.invested}
        </span>
      ),
    },

    {
      name: "TENURE DURATION",
      selector: (row) => row.tensure,
      cell: (row) => (
        <>
          <div className="flex flex-col">
            <div className="text-[#2272FF]">{row.tensure}%</div>
            <div
              className="mt-2"
              style={{
                width: "400%",
                height: "3px",
                backgroundColor: "#ddd",
                borderRadius: "4px",
                overflow: "hidden",
                color: "#2272FF",
              }}
            >
              <div
                style={{
                  width: `${row.tensure}%`,
                  height: "100%",
                  backgroundColor: "#2272FF",
                  borderRadius: "4px",
                }}
              />
            </div>
          </div>
        </>
      ),
    },
  ];
  return (
    <div className="bg-gray-100 p-3">
      {/* section 1 */}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
        <div className="bg-white rounded-lg p-4 flex items-center">
          <div className="flex-grow">
            <h3 className="text-sm text-gray-400 font-bold">Net Worth</h3>
            <p className="text-md font-bold">
              ₹53,00,000
              <span className="text-xxs text-green-500 font-semibold">
                {" "}
                +121%
              </span>
            </p>
          </div>
          <div>
            <IoMdShare className="text-white text-3xl bg-blue-500 p-1 rounded-xl group-hover:bg-blue-500 group-hover:text-white" />
          </div>
        </div>
        {/* 2 */}
        <div className="bg-white rounded-lg p-4 flex items-center">
          <div className="flex-grow">
            <h3 className="text-sm text-gray-400 font-bold">Today's Gain</h3>
            <p className="text-md font-bold">
              ₹2,173
              <span className="text-xxs text-green-500 font-semibold">
                {" "}
                +0.05%
              </span>
            </p>
          </div>
          <div>
            <TbBellRinging2Filled className="text-white text-3xl bg-blue-500 p-1 rounded-xl group-hover:bg-blue-500 group-hover:text-white" />
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 flex items-center">
          <div className="flex-grow">
            <h3 className="text-sm text-gray-400 font-bold">
              Total Profits Earned
            </h3>
            <p className="text-md font-bold">
              ₹9,54,000
              <span className="text-xxs text-green-500 font-semibold">
                {" "}
                +18%
              </span>
            </p>
          </div>
          <div>
            <HiLightBulb className="text-white text-3xl bg-blue-500 p-1 rounded-xl group-hover:bg-blue-500 group-hover:text-white" />
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 flex items-center">
          <div className="flex-grow">
            <h3 className="text-sm text-gray-400 font-bold">
              Capital Invested
            </h3>
            <p className="text-md font-bold">
              ₹43,56,000
              <span className="text-xxs text-green-500 font-semibold">
                {" "}
                100%
              </span>
            </p>
          </div>
          <div>
            <FaWallet className="text-white text-3xl bg-blue-500 p-1 rounded-xl group-hover:bg-blue-500 group-hover:text-white" />
          </div>
        </div>
      </div>

      <div class="flex flex-col mb-10 ml-5 mr-3  lg:flex-row lg:gap-4 ">
        {/* <!-- First Card --> */}
        <div class="lg:w-3/5 bg-white rounded-xl p-4 mb-4 lg:mb-0 flex flex-col items-center lg:flex-row gap-5">
          <div>
            <p class="text-sm text-gray-500 font-semibold">
              Built For Investors, Built By Investors
              <br />
            </p>
            <p class="font-bold">Investor’s Hub</p>
            <p class="text-sm text-gray-500 font-normal">
              5 Reasons Why Diversification is Key to a Successful Investment
              Strategy
            </p>
            <div class="flex items-center justify-between mt-4 lg:mt-20">
              <a
                href="/homepage/blog"
                class="text-black font-bold text-xl cursor-pointer hover:text-blue-500"
              >
                Read More <span class="ml-2 font-bold">&rarr;</span>
              </a>
            </div>
          </div>
          <div>
            <div class="bg-mygreen p-2 px-5 sm:w-52 rounded-lg grid place-items-center">
              <h1 class="text-black font-bold text-center">
                New Project Update
              </h1>
              <p class="text-center text-sm">Rio Global Warehouse Iv LLP</p>
              <ImClock class="text-[77px] text-white pt-4" />
              <p class="text-black text-center lg:mt-5">Launching Soon</p>
            </div>
          </div>
        </div>

        {/* <!-- Second Card --> */}
        <div class="lg:w-2/5  bg-white rounded-lg relative lg:mb-0 mb-5 p-2 md:p-4">
          {/* <!-- Your content for the second card goes here --> */}
          <div className="relative">
            <h1 className="absolute  left-2 text-white md:text-lg text-sm font-semibold">
              Learn How To Access Your Dashboard
              <br></br>
              <p className="sm:text-sm text-xs">
                Discover accessing your dashboard for instant insights on
                investments, profits, and portfolio at RioFin Assets
                effortlessly.
              </p>
            </h1>
            {/* <p>Discover accessing your dashboard for instant insights on investments, profits, and portfolio at RioFin Assets effortlessly.</p> */}

            <img
              src={yourThumbnailImage}
              alt="Video Thumbnail"
              className="w-full h-full object-cover cursor-pointer  border-mygreen border-r-8 border-b-8 rounded-[26px]"
              onClick={handlePlayClick}
            />
            <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-[50%] left-[50%]">
              <FaPlayCircle
                className="w-14 h-14 text-white cursor-pointer"
                onClick={handlePlayClick}
              />
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}

      {/* 4 th section */}
      <div className="md:mt-0 lg:mb-0 mb-5 mt-5 lg:-mt-10">
        <Active />
      </div>

      {/* fifth section */}
      <div className="flex lg:flex-row flex-col gap-3 p-5 lg:-mt-5 -mt-4">
        <div className="lg:w-4/5 sm:w-full w-full p-4 border bg-white rounded-xl">
          <h1 className="font-bold">Opportunities</h1>
          <p className="flex flex-row">
            <FaCheckCircle className="text-green-500 size-3 mt-1" />
            <span className="ml-2 text-gray-400 font-semibold mt-1 text-xs">
              7 Active deals listed
            </span>
          </p>
          <DataTable
            columns={columns}
            data={datas}
            customStyles={customStyles}
            className="mt-5"
          />
        </div>

        {/* sixth section */}
        <div className="lg:w-2/5 p-4 w-full  mr-3 border bg-white rounded-xl ">
          <div className="p-4  rounded-md">
            <h2 className="text-lg font-semibold mb-1">Activity Overview</h2>
            <h2 className="text-xs font-semibold text-green-600 mb-6">
              All Interactions
            </h2>

            <div className="flex flex-col  space-y-4">
              {steps.map((step) => (
                <div key={step.id} className="flex items-center h-10">
                  <div className="flex flex-col items-center">
                    <BiSolidBell className="text-blue-500" />
                    <div className="w-0.5 h-8 mt-1 bg-gray-300"></div>
                  </div>
                  <div className="flex flex-col ml-3 mb-3">
                    <p className="text-gray-700 font-medium text-sm whitespace-pre">
                      {step.text.split("").map((letter, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && index % 24 === 0 && <br />}
                          <span className="inline-block letter-shadow1">
                            {letter === " " ? "\u00A0" : letter}
                          </span>
                        </React.Fragment>
                      ))}
                    </p>
                    <p className="text-gray-400 text-xs">{step.date}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Dashboard;
