import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";



const Homepage = React.memo(() => {
  
  const [open, setOpen] = useState(false);
;

  return (
    <div className="w-full flex bg-[#F8F9FA] ">   

      <Navbar open={open} />
       <Sidebar open={open} />
       <div className="flex flex-col absolute left-1/5 right-0 top-16 w-full lg:w-4/5 z-0 ">        
        <Outlet />
      </div>
    </div>
  );
});

export default Homepage;
