import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Img from "../../../assets/Logo (1).png";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { Modal } from "antd";
import { GiCheckMark } from "react-icons/gi";

function Personalkyc() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(null);

  const handleClick = (buttonId) => {
    setIsLoading(true);
    setLoadingButton(buttonId);

    // Simulate an asynchronous operation (e.g., API request)
    setTimeout(() => {
      setIsLoading(false);
      setLoadingButton(null);
    }, 2000);
  };
  // State to manage form data
  const [formData, setFormData] = useState({
    aadhar: "",
    otp: "",
    pan: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log("Form submitted:", formData);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-100">
      <div className="md:p-9 p-5">
        <div className="flex"></div>
      </div>
      <div className="bg-[#F6F9FF] ml-3 md:mr-8 mr-3 lg:mr-8 border border-blue-500 shadow-lg rounded-lg lg:flex sm:grid-cols-1">
        {/* Left column - Profile Card */}
        <div className="bg-white lg:w-96 m-4 rounded-lg">
          <img
            src={Img}
            alt="Your Alt Text"
            className="  h-10 mt-4 ml-8 rounded-lg"
          />
          <div className="pl-8 pt-4 relative">
            <Link to="/homepage/profile">
              <p className="py-5 flex justify-start pl-3 items-center gap-3 hover:bg-white hover:text-black relative">
                <FaCheckCircle className="bg-white text-[#25C348] rounded-full text-xl" />
                Personal Details
              </p>
            </Link>
          </div>
        </div>

        {/* Right column - Information and Form */}
        <div className="lg:w-2/2 lg:w-full p-5 md:p-10">
          <div className="">
            <h2 className="font-bold lg:text-lg text-center md:text-left pb-6 pt-5 ">
              Address details
            </h2>
            {/* Form */}
            <form onSubmit={handleSubmit} className="flex flex-col gap-5">
              {/* Aadhar Number Field */}
              <div className="md:flex items-center">
                <label htmlFor="aadhar" className="text-[#1d5ca2]">
                  Aadhar No:
                </label>
                <div className="md:flex items-center  md:ml-4">
                  <input
                    id="aadhar"
                    name="aadhar"
                    type="number"
                    pattern="[0-9]*"
                    maxLength="12"
                    className="border border-gray-300 md:ml-2 w-full  rounded-md md:px-3  py-2"
                    onChange={handleChange}
                  />
                  <button
                    className={`text-white bg-blue-500 px-4 font-bold md:ml-3 py-2 rounded-md text-sm mt-2 md:mt-0 relative ${
                      isLoading && loadingButton === "button1"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => handleClick("button1")}
                    disabled={isLoading && loadingButton === "button1"}
                  >
                    {isLoading && loadingButton === "button1" && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                      </div>
                    )}
                    <span
                      className={
                        isLoading && loadingButton === "button1"
                          ? "opacity-0"
                          : "opacity-100"
                      }
                    >
                      Verify
                    </span>
                  </button>
                </div>
              </div>

              {/* OTP Field */}
              <div className="md:flex items-center">
                <label htmlFor="otp" className="text-[#1d5ca2]">
                  Verified OTP:
                </label>
                <div className="md:flex items-center md:ml-4">
                  <input
                    id="otp"
                    name="otp"
                    type="number"
                    pattern="[0-9]*"
                    maxLength="6"
                    className="border border-gray-300 w-full rounded-md md:px-3 px-auto py-2"
                    onChange={handleChange}
                  />
                  <button
                    className={`text-white bg-blue-500 px-4 font-bold md:ml-3 py-2 rounded-md text-sm mt-2 md:mt-0 relative ${
                      isLoading && loadingButton === "button2"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => handleClick("button2")}
                    disabled={isLoading && loadingButton === "button2"}
                  >
                    {isLoading && loadingButton === "button2" && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                      </div>
                    )}
                    <span
                      className={
                        isLoading && loadingButton === "button2"
                          ? "opacity-0"
                          : "opacity-100"
                      }
                    >
                      Verify
                    </span>
                  </button>
                </div>
              </div>

              {/* PAN Number Field */}
              <div className="md:flex items-center">
                <label htmlFor="pan" className="text-[#1d5ca2]">
                  PAN NO:
                </label>
                <div className="md:flex items-center md:ml-4">
                  <input
                    id="pan"
                    name="pan"
                    type="number"
                    pattern="[0-9]"
                    maxLength="10"
                    className="border  border-gray-300 md:ml-7 w-full rounded-md md:px-3 px-auto py-2"
                    onChange={handleChange}
                  />
                  <button
                    className={`text-white bg-blue-500 px-4 font-bold md:ml-3 py-2 rounded-md text-sm mt-2 md:mt-0 relative ${
                      isLoading && loadingButton === "button3"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={() => handleClick("button3")}
                    disabled={isLoading && loadingButton === "button3"}
                  >
                    {isLoading && loadingButton === "button3" && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
                      </div>
                    )}
                    <span
                      className={
                        isLoading && loadingButton === "button3"
                          ? "opacity-0"
                          : "opacity-100"
                      }
                    >
                      Verify
                    </span>
                  </button>
                </div>
              </div>

              {/* Update Button */}
              <div className="flex flex-row gap-5">
                <button
                  // onClick={showModal}
                  className="text-white bg-blue-500 px-5  font-bold py-3 text-sm rounded-md self-center lg:self-start "
                >
                  Submit
                </button>
                <Link to="/homepage/support">
                  <button className="text-white bg-blue-500 px-5  font-bold py-3 text-sm rounded-md self-center lg:self-start ">
                    Edit
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Outlet />
      {/* <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className=""
      >
        <div className="p-5 text-center pt-10 space-y-5 flex flex-col justify-center items-center">
          <GiCheckMark className="size-10 text-green-500"/>
          <h1 className="text-2xl text-green-500 font-semibold">Your details have been updated successfully.</h1>
          <p className="font-medium">Expect your reply within 48 hours from our team.</p>
        </div>
      </Modal> */}
    </div>
  );
}

export default Personalkyc;
